//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum WbDeliveryType {
  /** Fulfillment by Wildberries (Маркетплейс отвечает за хранение и доставку). */
  FBW = 0,
  /** Fulfillment by Seller (Продавец хранит товар, но доставка осуществляется через Wildberries). */
  FBS = 1
}
