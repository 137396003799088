import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { getUnixTime } from 'date-fns'

import { FormHelperText, Grid } from '@material-ui/core'

import { ButtonLoader } from './ButtonLoader'
import { DateTimeRangePicker } from './DateTimeRangePicker'

import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'

import { dateUtils } from '../common/dateTimeUtility'

import { LoadOrdersOptions } from '../server/mpsklad_core/Models/LoadOrdersOptions'
import { useSettingsPageStyles } from '../hooks/settingsPageStylesHook'

export type LoadOrdersFormProps = {
  accountId: number

  loadOrders: (options: LoadOrdersOptions) => Promise<number>
}

export const LoadOrdersForm =
  observer(
    ({accountId, loadOrders}: LoadOrdersFormProps) => {
      const {showSuccess, showWarning} = useMessages()

      const [startDate, setStartDate] = useState(dateUtils.startOfYesterday)
      const [endDate, setEndDate] = useState(dateUtils.now)

      const [isSubmitting, setSubmitting, setFinished] = useBoolState()
      const classes = useSettingsPageStyles()

      const onDateChange =
        (newStartDate: Date, newEndDate: Date) => {
          setStartDate(newStartDate)
          setEndDate(newEndDate)
        }

      const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault()

        try {
          setSubmitting()

          const orderCount = await loadOrders({
            accountId,
            startDateUnix: getUnixTime(startDate),
            endDateUnix: getUnixTime(endDate)
          })

          if (orderCount === 0) {
            showWarning('Нет заказов за выбранный период')
            return
          }

          showSuccess(`Загружено! Всего заказов за выбранный период: ${orderCount}`)
        } finally {
          setFinished()
        }
      }

      return (
        <form onSubmit={onSubmit}>
          <Grid container direction="column">
            <DateTimeRangePicker
              containerProps={{xl: 12}}
              startDate={startDate}
              endDate={endDate}
              maxDate={dateUtils.now}
              onChange={onDateChange}
            />

            <Grid item>
              <FormHelperText className={classes.helperText}>
                Загрузка может занять несколько минут для большого количества заказов.
              </FormHelperText>
            </Grid>

            <Grid item>

              <button
                type="submit"
                className="default-button"
                disabled={isSubmitting}
              >
                {
                  isSubmitting ? <ButtonLoader text="Загружаем"/>
                               : <p>Загрузить</p>
                }
              </button>

            </Grid>
          </Grid>
        </form>
      )
    })