import React, { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import { Box, FormHelperText, FormLabel } from '@material-ui/core'

import { ReactiveTabs } from './ReactiveTabs'
import { MyServerTable } from './MyServerTable'
import { OzonScreenTabs } from './OzonScreenTabs'
import { PullOrdersWithErrorsButton } from './PullOrdersWithErrorsButton'

import { useLogic, useStore } from '../hooks/storeHook'

import { ozonOrderColumns } from '../types/ozonOrderColumns'
import { OzonAccountRequiredProps } from '../types/accountProps'
import { OrdersTopPagination } from './OrdersTopPagination'
import search from '../assets/search.svg'
import { useOrderSearchStyles } from '../hooks/ordersSearchStylesHook'
import { BootstrapInput } from './BootstrapInput'

export const OzonOrdersTabsScreen = () =>
  <OzonScreenTabs ScreenComponent={OzonOrdersScreen}/>

// TODO: Generalize with WB, change filters? See OzonProductsTablePageOptions for ideas.
enum OzonOrdersTableTab {
  Active = 0,
  Cancelled = 1,
  WithErrors = 2,
}

const tabItems = [{
  name: 'Активные',
  value: OzonOrdersTableTab.Active
}, {
  name: 'Отменённые',
  value: OzonOrdersTableTab.Cancelled
}, {
  name: 'С ошибками',
  value: OzonOrdersTableTab.WithErrors
}]

const tabTitles = tabItems.map(_ => <span>{_.name}</span>)

export const OzonOrdersScreen =
  observer(
    ({account}: OzonAccountRequiredProps) => {
      const {api, ozonOrderLoaders} = useLogic()
      const {orderStore: {createOzonOrdersTable}} = useStore()
      const classes = useOrderSearchStyles()

      const ozonOrders = useMemo(
        () => createOzonOrdersTable(account.id),
        [createOzonOrdersTable, account])

      useEffect(() => ozonOrderLoaders.addHandler(ozonOrders.load), [ozonOrderLoaders, ozonOrders])

      const onTabChange =
        (newTabIndex: number) =>
          ozonOrders.reload(() => {
            // TODO: Replace newTabIndex parameter with an element of titles prop. Or use tabItems[newTabIndex].value
            // TODO: Forbid 'as' type assertion, search no-type-assertion/no-type-assertion
            switch (newTabIndex as OzonOrdersTableTab) {
              case OzonOrdersTableTab.Active:
                ozonOrders.setPropFilter('hasError', JSON.stringify(false))
                return

              case OzonOrdersTableTab.Cancelled:
                ozonOrders.setPropFilter('isCancelled', JSON.stringify(true))
                return

              case OzonOrdersTableTab.WithErrors:
                ozonOrders.setPropFilter('hasError', JSON.stringify(true))
                return

              default:
                throw new Error(`Unhandled value of OzonOrdersTableTab: ${newTabIndex}`)
            }
          })

      const currentTab =
        ozonOrders.findPropFilterValue('isCancelled') === JSON.stringify(true)
        ? OzonOrdersTableTab.Cancelled
        : ozonOrders.findPropFilterValue('hasError') === JSON.stringify(true)
          ? OzonOrdersTableTab.WithErrors
          : OzonOrdersTableTab.Active

      return (
        <ReactiveTabs titles={tabTitles} tab={currentTab} onTabChange={onTabChange}>
          <Box display="flex" justifyContent="space-between" className={classes.container}>
            <Box style={{position: 'relative'}}>
              <Box style={{minWidth: 350, maxWidth: 350}}>
                <FormLabel className={classes.root}>Поиск</FormLabel>
                <BootstrapInput
                  id="search-ozon-orders"
                  placeholder="Id / Id в Ozon / Номер отправления / Артикул товара"
                  onChange={e => ozonOrders.setGlobalFilter(e.target.value)}
                  inputProps={{'aria-label': 'search ozon orders'}}
                  fullWidth
                />
                <FormHelperText className={classes.root}>
                  Id / Id в Ozon / Номер отправления / Артикул товара
                </FormHelperText>
              </Box>
              <img className="search-icon" src={search} alt="Поиск" style={{top: 21, right: 20}}/>
            </Box>

            {
              currentTab === OzonOrdersTableTab.WithErrors &&
              <PullOrdersWithErrorsButton
                ordersTable={ozonOrders}
                pullOrders={() => api.order.pullOzonOrdersWithErrors(account.id)}
              />
            }

            <OrdersTopPagination table={ozonOrders}/>
          </Box>

          <MyServerTable
            hideTopPagination
            table={ozonOrders}
            columns={ozonOrderColumns}
            labelNoRows="Заказов нет"
            labelNotFound="Заказы не найдены"
            labelRowsPerPage="Заказов на странице:"
          />
        </ReactiveTabs>
      )
    })