import React, { useEffect, useMemo, useState } from 'react'
import { Redirect, Route, Switch, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Box, CircularProgress } from '@material-ui/core'

import { useApi } from '../../hooks/storeHook'
import { useMessages } from '../../hooks/snackbarHooks'
import { useLocalObservableOptional } from '../../common/mobxUtils'
import { required } from '../../common/objectUtils'

import { EditStoreAccountMsDataModel } from '../../server/mpsklad_core/Models/EditStoreAccountMsDataModel'
import { MoySkladApiEntityOption } from '../../server/mpsklad_core/Models/MoySkladApiEntityOption'
import { StoreAccountMsData } from '../MoySkladDataForm'
import { RoutingTabs } from '../RoutingTabs'
import { RouteTabItem } from '../RouteTabItem'
import { StoreAccountModelBase } from '../../server/mpsklad_core/Models/StoreAccountModelBase'
import { appRoutes, StoreRouteParams } from '../../common/appRoutes'
import { IntegrationTypeKey } from '../../types/integrationTypeUtils'
import { IntegrationType } from '../../server/mpsklad_core/Entity/IntegrationType'

interface StoreSettingsPageProps<TAccount extends StoreAccountModelBase> {
  account: TAccount | undefined;

  integrationTypeKey: Exclude<IntegrationTypeKey, 'MoySklad'>;

  apiSettingsComponent: React.FC<RouteComponentProps>;

  companySettingsComponent: React.FC<CompanySettingsProps<TAccount>>;

  ordersSettingsComponent: React.FC<{account: TAccount}>;

  warehousesSettingsComponent?: React.FC<{account: TAccount}>;

  returnsSettingsComponent?: React.FC<{msStores: MoySkladApiEntityOption[]}>;

  commissionsSettingsComponent?: React.FC<{account: TAccount}>;

  pricesSettingsComponent?: React.FC<{account: TAccount}>;

  onSubmit: (data: EditStoreAccountMsDataModel) => Promise<void>;

  additionalRoutes?: RouteTabItem[];
}

interface CompanySettingsProps<Account extends StoreAccountModelBase> extends RouteComponentProps {
  storeAccount: Account;

  msData: StoreAccountMsData;

  integrationType: Exclude<IntegrationType, IntegrationType.MoySklad>;

  msStores: MoySkladApiEntityOption[];

  onChange: (data: EditStoreAccountMsDataModel) => Promise<void>;
}

export const StoreSettingsPage = observer(
  <TAccount extends StoreAccountModelBase>(props: StoreSettingsPageProps<TAccount>) => {
    const {
      account,
      integrationTypeKey,
      apiSettingsComponent,
      companySettingsComponent,
      ordersSettingsComponent,
      warehousesSettingsComponent,
      returnsSettingsComponent,
      commissionsSettingsComponent,
      pricesSettingsComponent,
      onSubmit,
      additionalRoutes = []
    } = props

    const api = useApi()
    const {showError} = useMessages()

    const msContainer = useLocalObservableOptional<StoreAccountMsData>()
    const [msStores, setMsStores] = useState<MoySkladApiEntityOption[]>([])
    const [isLoading, setIsLoading] = useState(true)

    const appRoute = appRoutes.CommonStore

    useEffect(() => {
      const loadData = async () => {
        if (!account) {
          setIsLoading(false)
          return
        }
        try {
          const {organizations, counterparties, contracts, salesChannels, projects} =
            await api.userSync.getMoySkladData(IntegrationType[integrationTypeKey], account.id)

          if (organizations.length === 0) {
            showError('Нет организаций в МоёмСкладе')
            return
          }

          if (counterparties.length === 0) {
            showError('Нет контрагентов в МоёмСкладе с типом "Юридическое лицо"')
            return
          }

          msContainer.dataOrNull = {
            organization: {
              options: organizations,
              value:
                organizations.find((_) => _.id === account.msOrganization) ?? organizations[0],
              searchTerm: '',
              isSearchLoading: false
            },
            counterparty: {
              options: counterparties,
              value:
                counterparties.find((_) => _.id === account.msCounterparty) ?? counterparties[0],
              searchTerm: '',
              isSearchLoading: false
            },
            contract: {
              options: contracts,
              value: contracts.find((_) => _.id === account.msContractId) ?? null,
              searchTerm: '',
              isSearchLoading: false
            },
            salesChannel: {
              options: salesChannels,
              value: salesChannels.find((_) => _.id === account.msSalesChannelId) ?? null,
              searchTerm: '',
              isSearchLoading: false
            },
            project: {
              options: projects,
              value: projects.find((_) => _.id === account.msProjectId) ?? null,
              searchTerm: '',
              isSearchLoading: false
            },
            get formData() {
              return {
                msOrganization: this.organization.value.id,
                msCounterparty: this.counterparty.value.id,
                msContractId: this.contract.value?.id,
                msSalesChannelId: this.salesChannel.value?.id,
                msProjectId: this.project.value?.id
              }
            }
          }

          // Load MoySklad stores
          const msStores = await api.userSync.getMoySkladStores()
          setMsStores(msStores)
        } catch (e) {
          console.error('Failed to load data', e)
          showError('Не удалось загрузить данные из МоегоСклада')
        } finally {
          setIsLoading(false)
        }
      }

      setTimeout(loadData)
    }, [account, api.userSync, showError, msContainer, integrationTypeKey])

    const LoadingComponent = () => (
      <Box
        width="calc(100vw - 240px)"
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
          <CircularProgress/>
          <p>Загружаем данные из Моего Склада, подождите!</p>
        </Box>
      </Box>
    )

    const routes = useMemo(() => {
      const routeParams: StoreRouteParams = {
        accountId: account?.id,
        integration: integrationTypeKey
      }

      const baseRoutes: RouteTabItem[] = [
        {
          title: 'API',
          path: appRoute.settings.api.route(routeParams),
          template: appRoute.settings.api.template,
          isDisabled: false,
          render: (props: RouteComponentProps) =>
            React.createElement(apiSettingsComponent, {...props})
        },
        {
          title: 'Компания',
          path: appRoute.settings.company.route(routeParams),
          template: appRoute.settings.company.template,
          isDisabled: !account,
          render: (props: RouteComponentProps) =>
            account && msContainer.dataOrNull ? (
              React.createElement(companySettingsComponent, {
                storeAccount: account,
                msStores,
                msData: msContainer.dataOrNull,
                integrationType: IntegrationType[integrationTypeKey],
                onChange: onSubmit,
                ...props
              })
            ) : null
        },
        {
          title: 'Заказы',
          path: appRoute.settings.orders.route(routeParams),
          template: appRoute.settings.orders.template,
          isDisabled: !account,
          render: account
                  ? (props: RouteComponentProps) =>
                    React.createElement(ordersSettingsComponent, {account, ...props})
                  : () => null
        }
      ]

      if (warehousesSettingsComponent) {
        baseRoutes.push({
          title: 'Склады',
          path: appRoute.settings.warehouses.route(routeParams),
          template: appRoute.settings.warehouses.template,
          isDisabled: !account,
          render: account
                  ? (props: RouteComponentProps) =>
                    React.createElement(warehousesSettingsComponent, {account, ...props})
                  : () => null
        })
      }

      if (returnsSettingsComponent) {
        baseRoutes.push({
          title: 'Возвраты',
          path: appRoute.settings.returns.route(routeParams),
          template: appRoute.settings.returns.template,
          isDisabled: !account,
          render: account
                  ? (props: RouteComponentProps) =>
                    React.createElement(returnsSettingsComponent, {msStores, ...props})
                  : () => null
        })
      }

      if (commissionsSettingsComponent) {
        baseRoutes.push({
          title: 'Комиссии',
          path: appRoute.settings.commissions.route(routeParams),
          template: appRoute.settings.commissions.template,
          isDisabled: !account,
          render: account
                  ? (props: RouteComponentProps) =>
                    React.createElement(commissionsSettingsComponent, {account, ...props})
                  : () => null
        })
      }

      if (pricesSettingsComponent) {
        baseRoutes.push({
          title: 'Цены',
          path: appRoute.settings.prices.route(routeParams),
          template: appRoute.settings.prices.template,
          isDisabled: !account,
          render: account
                  ? (props: RouteComponentProps) =>
                    React.createElement(pricesSettingsComponent, {account, ...props})
                  : () => null
        })
      }

      return baseRoutes.concat(additionalRoutes || [])
    }, [
      integrationTypeKey,
      account,
      appRoute,
      apiSettingsComponent,
      companySettingsComponent,
      ordersSettingsComponent,
      warehousesSettingsComponent,
      returnsSettingsComponent,
      commissionsSettingsComponent,
      pricesSettingsComponent,
      onSubmit,
      msContainer.dataOrNull,
      msStores,
      additionalRoutes
    ])

    if (isLoading) {
      return <LoadingComponent/>
    }

    const firstRoutePath = required(routes[0].path)

    return (
      <RoutingTabs routeTabs={routes}>
        <Switch>
          <Route exact path={appRoute.settings.template}>
            <Redirect to={firstRoutePath}/>
          </Route>
          {routes.map(({render, template}, index) =>
            render ? <Route exact key={index} path={required(template)} render={render}/> : null
          )}

          <Redirect to={appRoutes.MoySklad.settings.initial}/>
        </Switch>
      </RoutingTabs>
    )
  }
)