import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

import { Box, FormLabel, Grid, Typography } from '@material-ui/core'

import { IOSSwitch } from '../../IOSSwitch'

import { useBoolState, useToggleState } from '../../../hooks/commonHooks'
import { useMessages } from '../../../hooks/snackbarHooks'
import { useApi, useLogic, useStore } from '../../../hooks/storeHook'

import { MoySkladApiEntityOption } from '../../../server/mpsklad_core/Models/MoySkladApiEntityOption'

import { useSettingsPageStyles } from '../../../hooks/settingsPageStylesHook'
import { DefaultSelect } from '../../DefaultSelect'

export type YandexMarketReturnsSettingsProps = {
  msStores: MoySkladApiEntityOption[]
}

export const YandexMarketReturnsSettings =
  observer(({msStores}: YandexMarketReturnsSettingsProps) => {
    const {showSuccess, showError} = useMessages()

    const logic = useLogic()
    const store = useStore()
    const {
      userSync: {
        setYandexMarketReturnsSettings
      }
    } = useApi()

    const account = store.getHomeYmAccount()

    const [isLoading, setIsLoading, setNotLoading] = useBoolState()

    const [msSalesReturnStoreId, setMsSalesReturn] = useState<string | undefined>(account?.msSalesReturnStoreId)

    const [autoReturnsEnabled, toggleAutoReturnEnabled] = useToggleState(account?.isAutoReturnEnabled ?? false)

    const classes = useSettingsPageStyles()

    const storeOptions = msStores.map(store => ({
      value: store.id,
      label: store.name
    }))

    const onSubmit = async () => {
      try {
        setIsLoading()
        await setYandexMarketReturnsSettings({
          accountId: account.id,
          isAutoReturnEnabled: autoReturnsEnabled,
          msSalesReturnId: msSalesReturnStoreId
        })

        setTimeout(logic.loadUserAccounts)

        showSuccess('Настройки возвратов обновлены!')
      } catch (e) {
        showError('Произошла ошибка при настройке возвратов!')
      } finally {
        setNotLoading()
      }
    }

    return (
      <Box className={classes.pageContainer}>
        <Typography className={classes.header}>НАСТРОЙКИ ВОЗВРАТОВ</Typography>
        <Grid container spacing={3}>
          <Grid item xs={7}>
            <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
              <Grid item className={classes.root} xs={12}>
                <Box>
                  <FormLabel component="legend" className={classes.label}>
                    <span className={classes.dash}>—</span>
                    Склад возвратов МоегоСклада
                  </FormLabel>
                </Box>

                <DefaultSelect<string>
                  value={msSalesReturnStoreId}
                  onChange={setMsSalesReturn}
                  options={storeOptions}
                  fullWidth
                  placeholder="Выберите склад"
                  withUnselectedValue
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={7}>
            <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
              <Grid item className={classes.root} xs={12}>
                <Box>
                  <FormLabel component="legend" className={classes.label}>
                    <span className={classes.dash}>—</span>
                    Склад, на который будет создан возврат
                  </FormLabel>
                </Box>

                <Box width={'100%'} display="flex" justifyContent="space-between" alignItems="center">
                  <FormLabel className={classes.unifiedPricesLabel}>
                    Создание документов о возврате
                  </FormLabel>
                  <IOSSwitch checked={autoReturnsEnabled} onChange={toggleAutoReturnEnabled}/>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box display="flex">
          <button
            className={clsx(classes.saveButton, 'default-button')}
            disabled={isLoading}
            onClick={onSubmit}
          >
            <p>{isLoading ? 'Сохраняем...' : 'Сохранить'}</p>
          </button>
        </Box>
      </Box>
    )
  })