import { styled } from '@material-ui/core/styles'
import { InputBase } from '@material-ui/core'

export const BootstrapInput = styled(InputBase)(({theme}) => ({
  width: '100%',
  '& .MuiInputBase-input': {
    borderRadius: 8,
    minHeight: 40,
    color: theme.palette.mpSkladSecondary.darkBlue1,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 12,
    padding: '10px 12px',
    fontFamily: 'Roboto Regular',
    '&:focus': {
      borderColor: '#80bdff'
    }
  }
}))