//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { UserSyncModel } from '../Models/UserSyncModel';
import { UserSyncResponseModel } from '../Models/UserSyncResponseModel';
import { SetAccountToggleModel } from '../Models/SetAccountToggleModel';
import { SetSyncStartDateModel } from '../Models/SetSyncStartDateModel';
import { OzonAccountModel } from '../Models/OzonAccountModel';
import { EditOzonAccountModel } from '../Models/EditOzonAccountModel';
import { OzonWarehouseModel } from '../Models/OzonWarehouseModel';
import { OzonStoreModel } from '../Models/OzonStoreModel';
import { EditStoresModel } from '../Models/EditStoresModel';
import { WbWarehouseModel } from '../Models/WbWarehouseModel';
import { WbStoreModel } from '../Models/WbStoreModel';
import { YandexMarketWarehouseModel } from '../Models/YandexMarketWarehouseModel';
import { YandexMarketStoreModel } from '../Models/YandexMarketStoreModel';
import { WbAccountModel } from '../Models/WbAccountModel';
import { EditWbAccountModel } from '../Models/EditWbAccountModel';
import { YandexMarketAccountModel } from '../Models/YandexMarketAccountModel';
import { EditYandexMarketAccountModel } from '../Models/EditYandexMarketAccountModel';
import { SyncLogModel } from '../Models/SyncLogModel';
import { SyncLogsRequestModel } from '../Models/SyncLogsRequestModel';
import { GetProductSyncLogsCombinedModel } from '../Models/GetProductSyncLogsCombinedModel';
import { IntegrationType } from '../Entity/IntegrationType';
import { MatchOzonToMoySkladModel } from '../Models/MatchOzonToMoySkladModel';
import { MatchWbToMoySkladModel } from '../Models/MatchWbToMoySkladModel';
import { MatchYandexMarketToMoySkladModel } from '../Models/MatchYandexMarketToMoySkladModel';
import { MoySkladAccountModel } from '../Models/MoySkladAccountModel';
import { EditMoySkladAccountModel } from '../Models/EditMoySkladAccountModel';
import { MoySkladStatusMapsModel } from '../Models/MoySkladStatusMapsModel';
import { MoySkladStatusMapModel } from '../Models/MoySkladStatusMapModel';
import { MoySkladDataModel } from '../Models/MoySkladDataModel';
import { MoySkladApiEntityOption } from '../Models/MoySkladApiEntityOption';
import { MoySkladStoreModel } from '../Models/MoySkladStoreModel';
import { MoySkladAttributeModel } from '../Models/MoySkladAttributeModel';
import { MoySkladSetAttributesModel } from '../Models/MoySkladSetAttributesModel';
import { YandexMarketAttributesForMoySkladModel } from '../Models/YandexMarketAttributesForMoySkladModel';
import { MoySkladOrderFeeType } from '../Models/MoySkladOrderFeeType';
import { CreateMsStoresModel } from '../Models/CreateMsStoresModel';
import { LoadOrdersOptions } from '../Models/LoadOrdersOptions';
import { SetMaximumProductStocksModel } from '../Entity/SetMaximumProductStocksModel';
import { YandexMarketOrdersSettings } from '../Models/YandexMarketOrdersSettings';
import { OzonOrdersSettings } from '../Models/OzonOrdersSettings';
import { WildberriesOrdersSettings } from '../Models/WildberriesOrdersSettings';
import { ReturnsSettings } from '../Models/ReturnsSettings';
import { WildberriesReturnsSettings } from '../Models/WildberriesReturnsSettings';
import { CommisionsSettings } from '../Models/CommisionsSettings';
import { PricesSettings } from '../Models/PricesSettings';

import { boundClass } from 'autobind-decorator';
import { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';
import { encodeUrlParameter, getAxiosData } from '../../../common/urlUtility';
@boundClass export class UserSyncController
{
  constructor (axios: AxiosInstance)
  {
    this.axios = axios
  }
  
  private readonly axios: AxiosInstance
  
  getSync(_config?: AxiosRequestConfig) : Promise<UserSyncModel>
  {
    return this.axios.get<UserSyncModel>(`UserSync/GetSync`, _config).then(getAxiosData)
  }
  

  syncCurrentUser(_config?: AxiosRequestConfig) : Promise<UserSyncResponseModel>
  {
    return this.axios.post<UserSyncResponseModel>(`UserSync/SyncCurrentUser`, null, _config).then(getAxiosData)
  }
  

  setOzonSync(setOzonModel: SetAccountToggleModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetOzonSync`, setOzonModel, _config).then(getAxiosData)
  }
  

  setOzonRealizationReportsSync(setOzonModel: SetAccountToggleModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetOzonRealizationReportsSync`, setOzonModel, _config).then(getAxiosData)
  }
  

  setWbSync(setWbModel: SetAccountToggleModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetWbSync`, setWbModel, _config).then(getAxiosData)
  }
  

  setWbRealizationReportsSync(setWbModel: SetAccountToggleModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetWbRealizationReportsSync`, setWbModel, _config).then(getAxiosData)
  }
  

  setYandexMarketSync(setYandexMarketModel: SetAccountToggleModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetYandexMarketSync`, setYandexMarketModel, _config).then(getAxiosData)
  }
  

  setMoySkladSync(sync: boolean, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetMoySkladSync/${encodeUrlParameter(sync)}`, null, _config).then(getAxiosData)
  }
  

  setOzonSyncStartDate(model: SetSyncStartDateModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetOzonSyncStartDate`, model, _config).then(getAxiosData)
  }
  

  setWbSyncStartDate(model: SetSyncStartDateModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetWbSyncStartDate`, model, _config).then(getAxiosData)
  }
  

  setYandexMarketSyncStartDate(model: SetSyncStartDateModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetYandexMarketSyncStartDate`, model, _config).then(getAxiosData)
  }
  

  setMoySkladSyncStartDate(model: SetSyncStartDateModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetMoySkladSyncStartDate`, model, _config).then(getAxiosData)
  }
  

  createOzonAccount(model: EditOzonAccountModel, _config?: AxiosRequestConfig) : Promise<OzonAccountModel>
  {
    return this.axios.post<OzonAccountModel>(`UserSync/CreateOzonAccount`, model, _config).then(getAxiosData)
  }
  

  editOzonAccount(model: EditOzonAccountModel, _config?: AxiosRequestConfig) : Promise<OzonAccountModel>
  {
    return this.axios.post<OzonAccountModel>(`UserSync/EditOzonAccount`, model, _config).then(getAxiosData)
  }
  

  deleteOzonAccount(accountId: number, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/DeleteOzonAccount/${encodeUrlParameter(accountId)}`, null, _config).then(getAxiosData)
  }
  

  setOzonMultiProducts(allow: boolean, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetOzonMultiProducts/${encodeUrlParameter(allow)}`, null, _config).then(getAxiosData)
  }
  

  setWbMultiProducts(allow: boolean, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetWbMultiProducts/${encodeUrlParameter(allow)}`, null, _config).then(getAxiosData)
  }
  

  setYandexMarketMultiProducts(allow: boolean, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetYandexMarketMultiProducts/${encodeUrlParameter(allow)}`, null, _config).then(getAxiosData)
  }
  

  getOzonWarehouses(ozonAccountId: number, _config?: AxiosRequestConfig) : Promise<OzonWarehouseModel[]>
  {
    return this.axios.get<OzonWarehouseModel[]>(`UserSync/GetOzonWarehouses?ozonAccountId=${encodeUrlParameter(ozonAccountId)}`, _config).then(getAxiosData)
  }
  

  getOzonStores(ozonAccountId: number, _config?: AxiosRequestConfig) : Promise<OzonStoreModel[]>
  {
    return this.axios.get<OzonStoreModel[]>(`UserSync/GetOzonStores?ozonAccountId=${encodeUrlParameter(ozonAccountId)}`, _config).then(getAxiosData)
  }
  

  editOzonStores(model: EditStoresModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/EditOzonStores`, model, _config).then(getAxiosData)
  }
  

  getWbWarehouses(wbAccountId: number, _config?: AxiosRequestConfig) : Promise<WbWarehouseModel[]>
  {
    return this.axios.get<WbWarehouseModel[]>(`UserSync/GetWbWarehouses?wbAccountId=${encodeUrlParameter(wbAccountId)}`, _config).then(getAxiosData)
  }
  

  getWbStores(wbAccountId: number, _config?: AxiosRequestConfig) : Promise<WbStoreModel[]>
  {
    return this.axios.get<WbStoreModel[]>(`UserSync/GetWbStores?wbAccountId=${encodeUrlParameter(wbAccountId)}`, _config).then(getAxiosData)
  }
  

  editWbStores(model: EditStoresModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/EditWbStores`, model, _config).then(getAxiosData)
  }
  

  getYandexMarketWarehouses(yandexMarketAccountId: number, _config?: AxiosRequestConfig) : Promise<YandexMarketWarehouseModel[]>
  {
    return this.axios.get<YandexMarketWarehouseModel[]>(`UserSync/GetYandexMarketWarehouses?yandexMarketAccountId=${encodeUrlParameter(yandexMarketAccountId)}`, _config).then(getAxiosData)
  }
  

  getYandexMarketStores(yandexMarketAccountId: number, _config?: AxiosRequestConfig) : Promise<YandexMarketStoreModel[]>
  {
    return this.axios.get<YandexMarketStoreModel[]>(`UserSync/GetYandexMarketStores?yandexMarketAccountId=${encodeUrlParameter(yandexMarketAccountId)}`, _config).then(getAxiosData)
  }
  

  editYandexMarketStores(model: EditStoresModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/EditYandexMarketStores`, model, _config).then(getAxiosData)
  }
  

  createWbAccount(model: EditWbAccountModel, _config?: AxiosRequestConfig) : Promise<WbAccountModel>
  {
    return this.axios.post<WbAccountModel>(`UserSync/CreateWbAccount`, model, _config).then(getAxiosData)
  }
  

  editWbAccount(model: EditWbAccountModel, _config?: AxiosRequestConfig) : Promise<WbAccountModel>
  {
    return this.axios.post<WbAccountModel>(`UserSync/EditWbAccount`, model, _config).then(getAxiosData)
  }
  

  deleteWbAccount(accountId: number, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/DeleteWbAccount/${encodeUrlParameter(accountId)}`, null, _config).then(getAxiosData)
  }
  

  createYandexMarketAccount(model: EditYandexMarketAccountModel, _config?: AxiosRequestConfig) : Promise<YandexMarketAccountModel>
  {
    return this.axios.post<YandexMarketAccountModel>(`UserSync/CreateYandexMarketAccount`, model, _config).then(getAxiosData)
  }
  

  editYandexMarketAccount(model: EditYandexMarketAccountModel, _config?: AxiosRequestConfig) : Promise<YandexMarketAccountModel>
  {
    return this.axios.post<YandexMarketAccountModel>(`UserSync/EditYandexMarketAccount`, model, _config).then(getAxiosData)
  }
  

  deleteYandexMarketAccount(accountId: number, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/DeleteYandexMarketAccount/${encodeUrlParameter(accountId)}`, null, _config).then(getAxiosData)
  }
  

  syncLogs(model: SyncLogsRequestModel, _config?: AxiosRequestConfig) : Promise<SyncLogModel[]>
  {
    return this.axios.post<SyncLogModel[]>(`UserSync/SyncLogs`, model, _config).then(getAxiosData)
  }
  

  productSyncLogsCombined(model: GetProductSyncLogsCombinedModel, _config?: AxiosRequestConfig) : Promise<SyncLogModel[]>
  {
    return this.axios.post<SyncLogModel[]>(`UserSync/ProductSyncLogsCombined`, model, _config).then(getAxiosData)
  }
  

  orderSyncLogsCombined(msOrderId: number, _config?: AxiosRequestConfig) : Promise<SyncLogModel[]>
  {
    return this.axios.get<SyncLogModel[]>(`UserSync/OrderSyncLogsCombined/${encodeUrlParameter(msOrderId)}`, _config).then(getAxiosData)
  }
  

  syncLog(integration: IntegrationType, logId: string, _config?: AxiosRequestConfig) : Promise<SyncLogModel>
  {
    return this.axios.get<SyncLogModel>(`UserSync/SyncLog/${encodeUrlParameter(integration)}/${encodeUrlParameter(logId)}`, _config).then(getAxiosData)
  }
  

  matchOzonToMoySkladProducts(model: MatchOzonToMoySkladModel, _config?: AxiosRequestConfig) : Promise<number>
  {
    return this.axios.post<number>(`UserSync/MatchOzonToMoySkladProducts`, model, _config).then(getAxiosData)
  }
  

  matchWbToMoySkladProducts(model: MatchWbToMoySkladModel, _config?: AxiosRequestConfig) : Promise<number>
  {
    return this.axios.post<number>(`UserSync/MatchWbToMoySkladProducts`, model, _config).then(getAxiosData)
  }
  

  matchYandexMarketToMoySkladProducts(model: MatchYandexMarketToMoySkladModel, _config?: AxiosRequestConfig) : Promise<number>
  {
    return this.axios.post<number>(`UserSync/MatchYandexMarketToMoySkladProducts`, model, _config).then(getAxiosData)
  }
  

  unmatchOzonProducts(ozonAccountId: number, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/UnmatchOzonProducts?ozonAccountId=${encodeUrlParameter(ozonAccountId)}`, null, _config).then(getAxiosData)
  }
  

  unmatchWbProducts(wbAccountId: number, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/UnmatchWbProducts?wbAccountId=${encodeUrlParameter(wbAccountId)}`, null, _config).then(getAxiosData)
  }
  

  unmatchYandexMarketProducts(yandexMarketAccountId: number, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/UnmatchYandexMarketProducts?yandexMarketAccountId=${encodeUrlParameter(yandexMarketAccountId)}`, null, _config).then(getAxiosData)
  }
  

  editMoySkladAccount(model: EditMoySkladAccountModel, _config?: AxiosRequestConfig) : Promise<MoySkladAccountModel>
  {
    return this.axios.post<MoySkladAccountModel>(`UserSync/EditMoySkladAccount`, model, _config).then(getAxiosData)
  }
  

  setMoySkladNumeration(isEnabled: boolean, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetMoySkladNumeration/${encodeUrlParameter(isEnabled)}`, null, _config).then(getAxiosData)
  }
  

  getMoySkladStatusMaps(_config?: AxiosRequestConfig) : Promise<MoySkladStatusMapsModel>
  {
    return this.axios.get<MoySkladStatusMapsModel>(`UserSync/GetMoySkladStatusMaps`, _config).then(getAxiosData)
  }
  

  setMoySkladStatusMaps(maps: MoySkladStatusMapModel[], _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetMoySkladStatusMaps`, maps, _config).then(getAxiosData)
  }
  

  verifyMoySkladWebhooks(_config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/VerifyMoySkladWebhooks`, null, _config).then(getAxiosData)
  }
  

  getMoySkladData(integrationType: IntegrationType, accountId?: number, _config?: AxiosRequestConfig) : Promise<MoySkladDataModel>
  {
    return this.axios.get<MoySkladDataModel>(`UserSync/GetMoySkladData?integrationType=${encodeUrlParameter(integrationType)}&accountId=${encodeUrlParameter(accountId)}`, _config).then(getAxiosData)
  }
  

  getMoySkladStores(_config?: AxiosRequestConfig) : Promise<MoySkladApiEntityOption[]>
  {
    return this.axios.get<MoySkladApiEntityOption[]>(`UserSync/GetMoySkladStores`, _config).then(getAxiosData)
  }
  

  getMoySkladStoresForAccount(storeType: IntegrationType, accountId: number, _config?: AxiosRequestConfig) : Promise<MoySkladStoreModel[]>
  {
    return this.axios.get<MoySkladStoreModel[]>(`UserSync/GetMoySkladStoresForAccount?storeType=${encodeUrlParameter(storeType)}&accountId=${encodeUrlParameter(accountId)}`, _config).then(getAxiosData)
  }
  

  getMoySkladOrganizations(searchTerm: string, _config?: AxiosRequestConfig) : Promise<MoySkladApiEntityOption[]>
  {
    return this.axios.get<MoySkladApiEntityOption[]>(`UserSync/GetMoySkladOrganizations?searchTerm=${encodeUrlParameter(searchTerm)}`, _config).then(getAxiosData)
  }
  

  getMoySkladCounterparties(searchTerm: string, _config?: AxiosRequestConfig) : Promise<MoySkladApiEntityOption[]>
  {
    return this.axios.get<MoySkladApiEntityOption[]>(`UserSync/GetMoySkladCounterparties?searchTerm=${encodeUrlParameter(searchTerm)}`, _config).then(getAxiosData)
  }
  

  getMoySkladContracts(searchTerm: string, _config?: AxiosRequestConfig) : Promise<MoySkladApiEntityOption[]>
  {
    return this.axios.get<MoySkladApiEntityOption[]>(`UserSync/GetMoySkladContracts?searchTerm=${encodeUrlParameter(searchTerm)}`, _config).then(getAxiosData)
  }
  

  getMoySkladSalesChannels(searchTerm: string, _config?: AxiosRequestConfig) : Promise<MoySkladApiEntityOption[]>
  {
    return this.axios.get<MoySkladApiEntityOption[]>(`UserSync/GetMoySkladSalesChannels?searchTerm=${encodeUrlParameter(searchTerm)}`, _config).then(getAxiosData)
  }
  

  getMoySkladProjects(searchTerm: string, _config?: AxiosRequestConfig) : Promise<MoySkladApiEntityOption[]>
  {
    return this.axios.get<MoySkladApiEntityOption[]>(`UserSync/GetMoySkladProjects?searchTerm=${encodeUrlParameter(searchTerm)}`, _config).then(getAxiosData)
  }
  

  getMoySkladAttributes(_config?: AxiosRequestConfig) : Promise<MoySkladAttributeModel[]>
  {
    return this.axios.get<MoySkladAttributeModel[]>(`UserSync/GetMoySkladAttributes`, _config).then(getAxiosData)
  }
  

  setMoySkladAttributes(model: MoySkladSetAttributesModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetMoySkladAttributes`, model, _config).then(getAxiosData)
  }
  

  setYandexMarketAttributesForMoySkladAccount(model: YandexMarketAttributesForMoySkladModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetYandexMarketAttributesForMoySkladAccount`, model, _config).then(getAxiosData)
  }
  

  createMoySkladOrderFeeAttribute(feeType: MoySkladOrderFeeType, _config?: AxiosRequestConfig) : Promise<MoySkladAttributeModel>
  {
    return this.axios.post<MoySkladAttributeModel>(`UserSync/CreateMoySkladOrderFeeAttribute?feeType=${encodeUrlParameter(feeType)}`, null, _config).then(getAxiosData)
  }
  

  importWarehousesToMs(model: CreateMsStoresModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/ImportWarehousesToMs`, model, _config).then(getAxiosData)
  }
  

  loadOzonOrders(options: LoadOrdersOptions, _config?: AxiosRequestConfig) : Promise<number>
  {
    return this.axios.post<number>(`UserSync/LoadOzonOrders`, options, _config).then(getAxiosData)
  }
  

  loadWbOrders(options: LoadOrdersOptions, _config?: AxiosRequestConfig) : Promise<number>
  {
    return this.axios.post<number>(`UserSync/LoadWbOrders`, options, _config).then(getAxiosData)
  }
  

  loadYandexMarketOrders(options: LoadOrdersOptions, _config?: AxiosRequestConfig) : Promise<number>
  {
    return this.axios.post<number>(`UserSync/LoadYandexMarketOrders`, options, _config).then(getAxiosData)
  }
  

  setOzonAutoReturnEnabled(model: SetAccountToggleModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetOzonAutoReturnEnabled`, model, _config).then(getAxiosData)
  }
  

  setWbAutoReturnEnabled(model: SetAccountToggleModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetWbAutoReturnEnabled`, model, _config).then(getAxiosData)
  }
  

  setWbAutoRemoveCancelledMsOrderDocs(model: SetAccountToggleModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetWbAutoRemoveCancelledMsOrderDocs`, model, _config).then(getAxiosData)
  }
  

  setYandexMarketAutoReturnEnabled(model: SetAccountToggleModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetYandexMarketAutoReturnEnabled`, model, _config).then(getAxiosData)
  }
  

  setOzonMaximumProductStocks(model: SetMaximumProductStocksModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetOzonMaximumProductStocks`, model, _config).then(getAxiosData)
  }
  

  setWbMaximumProductStocks(model: SetMaximumProductStocksModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetWbMaximumProductStocks`, model, _config).then(getAxiosData)
  }
  

  setYandexMarketMaximumProductStocks(model: SetMaximumProductStocksModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetYandexMarketMaximumProductStocks`, model, _config).then(getAxiosData)
  }
  

  setOzonVatEnabled(model: SetAccountToggleModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetOzonVatEnabled`, model, _config).then(getAxiosData)
  }
  

  setWbVatEnabled(model: SetAccountToggleModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetWbVatEnabled`, model, _config).then(getAxiosData)
  }
  

  setYandexMarketVatEnabled(model: SetAccountToggleModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetYandexMarketVatEnabled`, model, _config).then(getAxiosData)
  }
  

  setWbConvertNonRubleOrders(model: SetAccountToggleModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetWbConvertNonRubleOrders`, model, _config).then(getAxiosData)
  }
  

  setOzonFeedsGeneration(ozonAccountId: number, isEnabled: boolean, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetOzonFeedsGeneration/${encodeUrlParameter(ozonAccountId)}/${encodeUrlParameter(isEnabled)}`, null, _config).then(getAxiosData)
  }
  

  checkFeedsUsageOzon(ozonAccountId: number, _config?: AxiosRequestConfig) : Promise<boolean>
  {
    return this.axios.get<boolean>(`UserSync/CheckFeedsUsageOzon/${encodeUrlParameter(ozonAccountId)}`, _config).then(getAxiosData)
  }
  

  setOzonAutoConfirmation(model: SetAccountToggleModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetOzonAutoConfirmation`, model, _config).then(getAxiosData)
  }
  

  setWbAutoConfirmation(model: SetAccountToggleModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetWbAutoConfirmation`, model, _config).then(getAxiosData)
  }
  

  setYandexMarketAutoConfirmation(model: SetAccountToggleModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetYandexMarketAutoConfirmation`, model, _config).then(getAxiosData)
  }
  

  setOzonAutoShipment(model: SetAccountToggleModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetOzonAutoShipment`, model, _config).then(getAxiosData)
  }
  

  setWbAutoShipment(model: SetAccountToggleModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetWbAutoShipment`, model, _config).then(getAxiosData)
  }
  

  setYandexMarketAutoShipment(model: SetAccountToggleModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetYandexMarketAutoShipment`, model, _config).then(getAxiosData)
  }
  

  setOzonReserveMsOrders(model: SetAccountToggleModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetOzonReserveMsOrders`, model, _config).then(getAxiosData)
  }
  

  setWbReserveMsOrders(model: SetAccountToggleModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetWbReserveMsOrders`, model, _config).then(getAxiosData)
  }
  

  setYandexMarketReserveMsOrders(model: SetAccountToggleModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetYandexMarketReserveMsOrders`, model, _config).then(getAxiosData)
  }
  

  updatePricesOzon(ozonAccountId: number, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/UpdatePricesOzon/${encodeUrlParameter(ozonAccountId)}`, null, _config).then(getAxiosData)
  }
  

  updatePricesWb(wbAccountId: number, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/UpdatePricesWb/${encodeUrlParameter(wbAccountId)}`, null, _config).then(getAxiosData)
  }
  

  updatePricesYandexMarket(yandexMarketAccountId: number, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/UpdatePricesYandexMarket/${encodeUrlParameter(yandexMarketAccountId)}`, null, _config).then(getAxiosData)
  }
  

  setWbDisableApiProducts(wbAccountId: number, isDisabled: boolean, _config?: AxiosRequestConfig) : Promise<WbAccountModel>
  {
    return this.axios.post<WbAccountModel>(`UserSync/SetWbDisableApiProducts/${encodeUrlParameter(wbAccountId)}?isDisabled=${encodeUrlParameter(isDisabled)}`, null, _config).then(getAxiosData)
  }
  

  setYandexMarketOrdersSettings(settings: YandexMarketOrdersSettings, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetYandexMarketOrdersSettings`, settings, _config).then(getAxiosData)
  }
  

  setOzonOrdersSettings(settings: OzonOrdersSettings, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetOzonOrdersSettings`, settings, _config).then(getAxiosData)
  }
  

  setWildberriesOrdersSettings(settings: WildberriesOrdersSettings, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetWildberriesOrdersSettings`, settings, _config).then(getAxiosData)
  }
  

  setYandexMarketReturnsSettings(settings: ReturnsSettings, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetYandexMarketReturnsSettings`, settings, _config).then(getAxiosData)
  }
  

  setOzonReturnsSettings(settings: ReturnsSettings, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetOzonReturnsSettings`, settings, _config).then(getAxiosData)
  }
  

  setWildberriesReturnsSettings(settings: WildberriesReturnsSettings, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetWildberriesReturnsSettings`, settings, _config).then(getAxiosData)
  }
  

  setCommissionsSettings(settings: CommisionsSettings, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetCommissionsSettings`, settings, _config).then(getAxiosData)
  }
  

  setIsUpdatePrices(settings: PricesSettings, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`UserSync/SetIsUpdatePrices`, settings, _config).then(getAxiosData)
  }
  

  /** Gets the full relative URL for the action */
  getSyncUrl(_config?: AxiosRequestConfig) : string
  {
    return `/api/UserSync/GetSync`
  }
  

  /** Gets the full relative URL for the action */
  getOzonWarehousesUrl(ozonAccountId: number, _config?: AxiosRequestConfig) : string
  {
    return `/api/UserSync/GetOzonWarehouses?ozonAccountId=${encodeUrlParameter(ozonAccountId)}`
  }
  

  /** Gets the full relative URL for the action */
  getOzonStoresUrl(ozonAccountId: number, _config?: AxiosRequestConfig) : string
  {
    return `/api/UserSync/GetOzonStores?ozonAccountId=${encodeUrlParameter(ozonAccountId)}`
  }
  

  /** Gets the full relative URL for the action */
  getWbWarehousesUrl(wbAccountId: number, _config?: AxiosRequestConfig) : string
  {
    return `/api/UserSync/GetWbWarehouses?wbAccountId=${encodeUrlParameter(wbAccountId)}`
  }
  

  /** Gets the full relative URL for the action */
  getWbStoresUrl(wbAccountId: number, _config?: AxiosRequestConfig) : string
  {
    return `/api/UserSync/GetWbStores?wbAccountId=${encodeUrlParameter(wbAccountId)}`
  }
  

  /** Gets the full relative URL for the action */
  getYandexMarketWarehousesUrl(yandexMarketAccountId: number, _config?: AxiosRequestConfig) : string
  {
    return `/api/UserSync/GetYandexMarketWarehouses?yandexMarketAccountId=${encodeUrlParameter(yandexMarketAccountId)}`
  }
  

  /** Gets the full relative URL for the action */
  getYandexMarketStoresUrl(yandexMarketAccountId: number, _config?: AxiosRequestConfig) : string
  {
    return `/api/UserSync/GetYandexMarketStores?yandexMarketAccountId=${encodeUrlParameter(yandexMarketAccountId)}`
  }
  

  /** Gets the full relative URL for the action */
  orderSyncLogsCombinedUrl(msOrderId: number, _config?: AxiosRequestConfig) : string
  {
    return `/api/UserSync/OrderSyncLogsCombined/${encodeUrlParameter(msOrderId)}`
  }
  

  /** Gets the full relative URL for the action */
  syncLogUrl(integration: IntegrationType, logId: string, _config?: AxiosRequestConfig) : string
  {
    return `/api/UserSync/SyncLog/${encodeUrlParameter(integration)}/${encodeUrlParameter(logId)}`
  }
  

  /** Gets the full relative URL for the action */
  getMoySkladStatusMapsUrl(_config?: AxiosRequestConfig) : string
  {
    return `/api/UserSync/GetMoySkladStatusMaps`
  }
  

  /** Gets the full relative URL for the action */
  getMoySkladDataUrl(integrationType: IntegrationType, accountId?: number, _config?: AxiosRequestConfig) : string
  {
    return `/api/UserSync/GetMoySkladData?integrationType=${encodeUrlParameter(integrationType)}&accountId=${encodeUrlParameter(accountId)}`
  }
  

  /** Gets the full relative URL for the action */
  getMoySkladStoresUrl(_config?: AxiosRequestConfig) : string
  {
    return `/api/UserSync/GetMoySkladStores`
  }
  

  /** Gets the full relative URL for the action */
  getMoySkladStoresForAccountUrl(storeType: IntegrationType, accountId: number, _config?: AxiosRequestConfig) : string
  {
    return `/api/UserSync/GetMoySkladStoresForAccount?storeType=${encodeUrlParameter(storeType)}&accountId=${encodeUrlParameter(accountId)}`
  }
  

  /** Gets the full relative URL for the action */
  getMoySkladOrganizationsUrl(searchTerm: string, _config?: AxiosRequestConfig) : string
  {
    return `/api/UserSync/GetMoySkladOrganizations?searchTerm=${encodeUrlParameter(searchTerm)}`
  }
  

  /** Gets the full relative URL for the action */
  getMoySkladCounterpartiesUrl(searchTerm: string, _config?: AxiosRequestConfig) : string
  {
    return `/api/UserSync/GetMoySkladCounterparties?searchTerm=${encodeUrlParameter(searchTerm)}`
  }
  

  /** Gets the full relative URL for the action */
  getMoySkladContractsUrl(searchTerm: string, _config?: AxiosRequestConfig) : string
  {
    return `/api/UserSync/GetMoySkladContracts?searchTerm=${encodeUrlParameter(searchTerm)}`
  }
  

  /** Gets the full relative URL for the action */
  getMoySkladSalesChannelsUrl(searchTerm: string, _config?: AxiosRequestConfig) : string
  {
    return `/api/UserSync/GetMoySkladSalesChannels?searchTerm=${encodeUrlParameter(searchTerm)}`
  }
  

  /** Gets the full relative URL for the action */
  getMoySkladProjectsUrl(searchTerm: string, _config?: AxiosRequestConfig) : string
  {
    return `/api/UserSync/GetMoySkladProjects?searchTerm=${encodeUrlParameter(searchTerm)}`
  }
  

  /** Gets the full relative URL for the action */
  getMoySkladAttributesUrl(_config?: AxiosRequestConfig) : string
  {
    return `/api/UserSync/GetMoySkladAttributes`
  }
  

  /** Gets the full relative URL for the action */
  checkFeedsUsageOzonUrl(ozonAccountId: number, _config?: AxiosRequestConfig) : string
  {
    return `/api/UserSync/CheckFeedsUsageOzon/${encodeUrlParameter(ozonAccountId)}`
  }
  

}
