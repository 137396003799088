import { makeStyles } from '@material-ui/core/styles'

export const useSettingsPageStyles = makeStyles(
  () => ({
    pageContainer: {
      margin: '0 40px 20px 0px'
    },
    fullWidth: {
      width: 'calc(100vw - 300px)'
    },
    root: {
      padding: 15
    },
    settingsTopic: {
      borderRadius: 12,
      padding: 15,
      width: '100%'
    },
    label: {
      fontFamily: 'Roboto Regular',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.4px',
      textAlign: 'left',
      marginBottom: 15
    },
    input: {
      fontFamily: 'Roboto Regular',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.4px !important',
      textAlign: 'left'
    },
    dash: {
      color: '#3987CF',
      marginRight: 3
    },
    star: {
      color: '#3987CF',
      marginLeft: 2
    },
    header: {
      fontFamily: 'Roboto Regular',
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '21.6px',
      textAlign: 'left',
      marginBottom: 32,
      marginTop: 10,
      textTransform: 'uppercase'
    },
    workflow: {
      '& span': {
        color: '#1F364D',
        fontFamily: 'Roboto Regular',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '14.4px',
        textAlign: 'left'
      },
      '& .Mui-disabled span': {
        color: '#888888 !important'
      },
      '& span:not(.Mui-disabled)': {
        color: '#3987CF'
      }
    },
    workflowLabel: {
      color: '#1F364D !important'
    },
    saveButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      alignItems: 'center'
    },
    helperText: {
      fontSize: 11,
      fontWeight: 400,
      marginTop: 10
    },
    boldLabel: {
      fontSize: 12,
      marginBottom: 12,
      fontWeight: 600,
      color: '#1F364D'
    },
    unifiedPricesLabel: {
      fontFamily: 'Roboto Regular',
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '14.4px',
      textAlign: 'left',
      color: '#1F364D'
    },
    supplySettingsContainer: {
      flexWrap: 'unset',
      marginTop: 20,
      marginBottom: 20,
      marginLeft: 0,
      padding: 20,
      borderRadius: 12,
      width: 'calc(100% - 40px)'
    }
  })
)