import React, { MouseEventHandler, useMemo } from 'react'
import { runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react-lite'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import { Box, CircularProgress, Collapse, FormHelperText, Grid, Typography } from '@material-ui/core'

import { DefaultRadioItem } from './DefaultRadioItem'
import { StoresFormDirect } from './StoresFormDirect'
import { WarehouseKeyProps } from './WarehousePicker'
import { DefaultRadioOption } from './DefaultRadioGroup'
import { StoresFormSumFromMoySklad } from './StoresFormSumFromMoySklad'
import { StoresFormSplitFromMoySklad } from './StoresFormSplitFromMoySklad'

import { useMessages } from '../hooks/snackbarHooks'
import { useApi, useLogic } from '../hooks/storeHook'
import { LoadState, useLoadState } from '../hooks/loadStateHook'
import { useBoolState, useLazyEffect } from '../hooks/commonHooks'

import { useLocalObservableOptional } from '../common/mobxUtils'

import { getIntegrationTypeName } from '../types/navStore'
import { StoreAccountRequiredProps } from '../types/accountProps'

import { EditStoreModel } from '../server/mpsklad_core/Models/EditStoreModel'
import { StoreModelBase } from '../server/mpsklad_core/Models/StoreModelBase'
import { WarehouseModel } from '../server/mpsklad_core/Models/WarehouseModel'
import { EditStoresModel } from '../server/mpsklad_core/Models/EditStoresModel'
import { IntegrationType } from '../server/mpsklad_core/Entity/IntegrationType'
import { MoySkladStoreModel } from '../server/mpsklad_core/Models/MoySkladStoreModel'
import { CreateMsStoresModel } from '../server/mpsklad_core/Models/CreateMsStoresModel'
import { StoreRelationType } from '../server/mpsklad_core/Entity/Base/StoreRelationType'
import { StoreAccountModelBase } from '../server/mpsklad_core/Models/StoreAccountModelBase'

export type AccountStoresFormProps<TAccount extends StoreAccountModelBase, TWarehouse extends WarehouseModel> =
  WarehouseKeyProps<TWarehouse>
  & StoreAccountRequiredProps<TAccount>
  & {
  hint?: string

  formatWarehouseNameHint?: (warehouse: TWarehouse) => string

  loadWarehouses: (accountId: number) => Promise<TWarehouse[]>

  loadStores: (accountId: number) => Promise<StoreModelBase[]>

  loadingHelpText?: string

  editStores: (model: EditStoresModel) => Promise<void>
}

export type AccountStoresServerData<TWarehouse extends WarehouseModel> = {
  stores: StoreModelBase[]

  warehouses: TWarehouse[]

  msStores: MoySkladStoreModel[]
}

export const AccountStoresForm =
  observer(
    <TAccount extends StoreAccountModelBase, TWarehouse extends WarehouseModel>
    ({
       account, hint,
       loadWarehouses, loadStores, loadingHelpText, editStores,
       whKeySelector, storeWhKeySelector,
       formatWarehouseNameHint
     }: AccountStoresFormProps<TAccount, TWarehouse>) => {
      const integrationTypeName = getIntegrationTypeName(account.integrationType)

      const classes = useStyles()
      const {showSuccess, showError} = useMessages()

      const api = useApi()
      const {showDialog} = useLogic()

      const {loadState, setLoading, setSuccess, setError} = useLoadState(LoadState.Loading)

      const [isSubmitting, setSubmitting, setSubmitted] = useBoolState()

      const serverData =
        useLocalObservable(() => ({
          stores: Array.of<StoreModelBase>(),
          warehouses: Array.of<TWarehouse>(),
          msStores: Array.of<MoySkladStoreModel>()
        } satisfies AccountStoresServerData<TWarehouse>))

      const formData = useLocalObservableOptional<EditStoresModel>()

      const relationTypeOptions =
        useMemo(() => ({
            direct: {
              value: StoreRelationType.Direct,
              label: 'Базовое сопоставление',
              hint: 'Сопоставляем склады один к одному.'
            } satisfies DefaultRadioOption<StoreRelationType>,
            sum: {
              value: StoreRelationType.SumFromMoySklad,
              label: 'Суммирование',
              hint: 'Остатки суммируются с нескольких складов МоегоСклада на один склад маркетплейса.'
            } satisfies DefaultRadioOption<StoreRelationType>,
            split: {
              value: StoreRelationType.SplitFromMoySklad,
              label: 'Разделение',
              hint: 'Остатки разделяются по нескольким складам маркетплейса. Разделение устанавливается в процентах.'
            } satisfies DefaultRadioOption<StoreRelationType>
          }),
          [])

      const importWarehousesToMs =
        async (warehouses: CreateMsStoresModel) => {
          await api.userSync.importWarehousesToMs(warehouses)
          await loadMsStores()
        }

      const formatWarehouseName =
        (warehouse: TWarehouse): string =>
          `${warehouse.id ? `${warehouse.id} ` : ''}${warehouse.name}`

      const formatWarehouseDisplayName =
        (warehouse: TWarehouse) =>
          `${formatWarehouseName(warehouse)}${formatWarehouseNameHint ? ` ${formatWarehouseNameHint(warehouse)}` : ''}`

      const loadMsStores =
        async () =>
          serverData.msStores = await api.userSync.getMoySkladStoresForAccount(account.integrationType, account.id)

      const initFormData = () =>
        formData.dataOrNull = {
          accountId: account.id,
          relationType: account.storeRelationType,
          ordersMsStoreId: account.ordersMsStoreId,
          stores: serverData.stores.map(_ => ({
            storeId: _.id,
            warehouseId: _.warehouseId,
            warehouseName: _.name,
            stocksSplitPercentage: _.stocksSplitPercentage,
            msStoreIds: _.msStoreIds
          }))
        }

      const onLoad =
        async (onBeforeInit?: () => void) => {
          setLoading()

          try {
            serverData.stores = await loadStores(account.id)
            serverData.warehouses = await loadWarehouses(account.id)

            await loadMsStores()

            runInAction(() => {
              onBeforeInit?.()
              initFormData()
            })

            if (serverData.stores.length === 0 && serverData.msStores.length === 0) {
              showError(`Не найдены склады ни ${integrationTypeName}, ни МоегоСклада!`)
              setError()
              return
            }

            setSuccess()
          } catch (e) {
            console.error('Failed to load', e)
            setError()
          }
        }

      const onImport =
        async (warehouse: TWarehouse) => {
          setSubmitting()

          try {
            if (warehouse) {
              await importWarehousesToMs({warehousesNames: [formatWarehouseName(warehouse)]})
              showSuccess(`Склад создан в МоёмСкладе!`)
            }
          } catch (e) {
            console.error('Failed to import warehouse to MS', e)
          } finally {
            setSubmitted()
          }
        }

      const onImportAll =
        async () => {
          setSubmitting()

          try {
            const warehousesNames = serverData.warehouses.map(warehouse => formatWarehouseName(warehouse))
            await importWarehousesToMs({warehousesNames})
            showSuccess(`Склады созданы в МоёмСкладе!`)
          } catch (e) {
            console.error('Failed to import all warehouses to MS', e)
            setError()
            return
          } finally {
            setSubmitted()
          }

          await onLoad()
        }

      const onCancel =
        async () => {
          if (await showDialog('Матчинг складов будет сброшен к предыдущему состоянию.', {
            title: 'Отменить изменения?',
            acceptButton: 'Отменить изменения',
            declineButton: 'Продолжить матчинг'
          })) {
            initFormData()
          }
        }

      const onSubmit =
        async () => {
          // Omit unmatched stores
          formData.data.stores = formData.data.stores.filter(_ => _.msStoreIds.length > 0)

          setSubmitting()

          try {
            await editStores(formData.data)
            showSuccess('Склады сохранены!')

            await onLoad(() => {
              // Update account props atomically, otherwise validation throws errors
              account.storeRelationType = formData.data.relationType
              account.ordersMsStoreId = formData.data.ordersMsStoreId
            })
          } catch (e) {
            console.error('Failed to save stores', e)
          } finally {
            setSubmitted()
          }
        }

      const onRelationTypeChange =
        async (newValue: StoreRelationType) => {
          const {data} = formData

          switch (newValue) {
            case StoreRelationType.Direct: {
              if ((data.stores.length > 1 || data.stores.flatMap(_ => _.msStoreIds).length > 1)
                  && !await showDialog(
                  `Необходимо выбрать склады ${integrationTypeName} и МоегоСклада без повторений!`)) {
                return
              }

              runInAction(() => {
                const extraStores: EditStoreModel[] = []

                for (const formStore of data.stores) {
                  if (formStore.msStoreIds.length > 1) {
                    extraStores.push(
                      ...formStore.msStoreIds
                                  .slice(1)
                                  .map(msStoreId => ({
                                    ...formStore,
                                    storeId: undefined,
                                    msStoreIds: [msStoreId]
                                  } satisfies EditStoreModel)))

                    formStore.msStoreIds = [formStore.msStoreIds[0]]
                  }
                }

                data.stores.push(...extraStores)
                data.ordersMsStoreId = undefined
                data.relationType = StoreRelationType.Direct
              })
              return
            }

            case StoreRelationType.SumFromMoySklad: {
              if (data.stores.length > 1
                  && !await showDialog(`Матчинг складов ${integrationTypeName} будет сброшен!`)) {
                return
              }

              runInAction(() => {
                if (data.stores.length > 0) {
                  const msStoreIds = data.stores.flatMap(_ => _.msStoreIds)

                  data.stores = [{
                    ...data.stores[0],
                    msStoreIds: msStoreIds.length > 0 ? [msStoreIds[0]] : []
                  }]
                } else {
                  data.stores = []
                }

                data.ordersMsStoreId = serverData.msStores[0]?.id
                data.relationType = StoreRelationType.SumFromMoySklad
              })
              return
            }

            case StoreRelationType.SplitFromMoySklad: {
              const msStoreIds = data.stores.flatMap(_ => _.msStoreIds)

              if (msStoreIds.length > 1 && !await showDialog('Матчинг складов МоегоСклада будет сброшен!')) {
                return
              }

              runInAction(() => {
                if (msStoreIds.length > 0) {
                  for (const formStore of data.stores) {
                    formStore.msStoreIds = [msStoreIds[0]]
                    formStore.stocksSplitPercentage = 0
                  }
                }

                data.ordersMsStoreId = undefined
                data.relationType = StoreRelationType.SplitFromMoySklad
              })
              return
            }

            default:
              throw new Error(`Unprocessed value of StoreRelationType: ${newValue}.`)
          }
        }

      const onRelationTypeClick =
        (newValue: StoreRelationType): MouseEventHandler<unknown> =>
          async e => {
            e.preventDefault()
            e.stopPropagation()

            await onRelationTypeChange(newValue)
          }

      useLazyEffect(onLoad)

      if (account.integrationType === IntegrationType.MoySklad) {
        throw new Error('Bad IntegrationType!')
      }

      return (
        <Box className={classes.pageContainer}>
          <Typography className={classes.header}>
            Настройка складов
          </Typography>

          {
            (loadState === LoadState.Initial || loadState === LoadState.Error) &&
            <button className={clsx(classes.saveButton, 'default-button')} onClick={() => onLoad()}>
              <p>Загрузить</p>
            </button>
          }

          {
            loadState === LoadState.Loading &&
            <>
              <CircularProgress size={25}/>

              {
                !!loadingHelpText &&
                <FormHelperText>
                  {loadingHelpText}
                </FormHelperText>
              }
            </>
          }

          {
            loadState === LoadState.Success &&
            <Grid container direction="column" spacing={4}>
              <Grid item xs={12}>
                <Grid container direction="column" className={clsx(classes.settingsTopic, 'default-border')}>
                  <Grid item xs={12}>
                    <DefaultRadioItem
                      disabled={isSubmitting}
                      value={formData.data.relationType}
                      option={relationTypeOptions.direct}
                      onClick={onRelationTypeClick(relationTypeOptions.direct.value)}
                      optionRadioClassName={classes.relationRadio}
                      optionClassName={classes.relationOption}
                      optionLabelClassName={clsx(classes.subHeader, classes.relationLabel)}
                    />
                  </Grid>

                  <Grid item xs={12} className={classes.relationContent}>
                    <Collapse in={formData.data.relationType === relationTypeOptions.direct.value}>
                      {
                        !!hint &&
                        <Box marginTop={3}>
                          <FormHelperText>
                            {hint}
                          </FormHelperText>
                        </Box>
                      }

                      {
                        // TODO: Extract common markup, update props per component
                        formData.data.relationType === relationTypeOptions.direct.value &&
                        <StoresFormDirect
                          disabled={isSubmitting}
                          storeType={account.integrationType}
                          serverData={serverData}
                          formData={formData.data}
                          whKeySelector={whKeySelector}
                          storeWhKeySelector={storeWhKeySelector}
                          formatWarehouseDisplayName={formatWarehouseDisplayName}
                          onImport={onImport}
                          onImportAll={onImportAll}
                        />
                      }
                    </Collapse>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                  <Grid item xs={12}>
                    <DefaultRadioItem
                      disabled={isSubmitting}
                      value={formData.data.relationType}
                      option={relationTypeOptions.sum}
                      onClick={onRelationTypeClick(relationTypeOptions.sum.value)}
                      optionRadioClassName={classes.relationRadio}
                      optionClassName={classes.relationOption}
                      optionLabelClassName={clsx(classes.subHeader, classes.relationLabel)}
                    />
                  </Grid>

                  <Grid item xs={12} className={classes.relationContent}>
                    <Collapse in={formData.data.relationType === relationTypeOptions.sum.value}>
                      {
                        formData.data.relationType === relationTypeOptions.sum.value &&

                        <StoresFormSumFromMoySklad
                          disabled={isSubmitting}
                          storeType={account.integrationType}
                          serverData={serverData}
                          formData={formData.data}
                          whKeySelector={whKeySelector}
                          storeWhKeySelector={storeWhKeySelector}
                          formatWarehouseDisplayName={formatWarehouseDisplayName}
                          onImport={onImport}/>
                      }
                    </Collapse>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                  <Grid item xs={12}>
                    <DefaultRadioItem
                      disabled={isSubmitting}
                      value={formData.data.relationType}
                      option={relationTypeOptions.split}
                      onClick={onRelationTypeClick(relationTypeOptions.split.value)}
                      optionRadioClassName={classes.relationRadio}
                      optionClassName={classes.relationOption}
                      optionLabelClassName={clsx(classes.subHeader, classes.relationLabel)}
                    />
                  </Grid>

                  <Grid item xs={12} className={classes.relationContent}>
                    <Collapse in={formData.data.relationType === relationTypeOptions.split.value}>
                      {
                        formData.data.relationType === relationTypeOptions.split.value &&
                        <StoresFormSplitFromMoySklad
                          disabled={isSubmitting}
                          storeType={account.integrationType}
                          serverData={serverData}
                          formData={formData.data}
                          whKeySelector={whKeySelector}
                          storeWhKeySelector={storeWhKeySelector}
                          formatWarehouseDisplayName={formatWarehouseDisplayName}
                          onImport={onImport}/>
                      }
                    </Collapse>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container xs={12} spacing={1} className={classes.buttonsItem}>
                <Grid item xs={'auto'}>
                  <button
                    disabled={isSubmitting}
                    className={clsx(classes.cancelButton, 'default-button')}
                    onClick={onCancel}>
                    <p>Отменить</p>
                  </button>
                </Grid>

                <Grid item xs={'auto'}>
                  <button
                    disabled={isSubmitting}
                    className={clsx(classes.saveButton, 'default-button')}
                    onClick={onSubmit}>
                    <p>Сохранить</p>
                  </button>
                </Grid>
              </Grid>
            </Grid>
          }
        </Box>
      )
    })

const useStyles = makeStyles(
  ({spacing, palette}) => ({
    pageContainer: {
      margin: '0 40px 40px 40px'
    },
    settingsTopic: {
      borderRadius: 12,
      padding: spacing(3)
    },
    label: {
      fontFamily: 'Roboto Regular',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.4px',
      textAlign: 'left',
      marginBottom: 15
    },
    dash: {
      color: '#3987CF',
      marginRight: 3
    },
    header: {
      fontFamily: 'Roboto Regular',
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '21.6px',
      textAlign: 'left',
      margin: '10px 10px 30px 0',
      textTransform: 'uppercase'
    },
    buttonsItem: {
      marginTop: 8,
      '& button': {
        marginTop: 0,
        marginBottom: 0
      }
    },
    saveButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      alignItems: 'center'
    },
    cancelButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      alignItems: 'center',
      backgroundColor: palette.grey.A100
    },
    subHeader: {
      fontFamily: 'Roboto Regular',
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '16.8px',
      textTransform: 'uppercase'
    },
    relationRadio: {
      marginTop: -22
    },
    relationOption: {
      '& span': {
        color: '#1F364D',
        fontFamily: 'Roboto Regular',
        lineHeight: '14.4px',
        textAlign: 'left'
      },
      '& .Mui-disabled span': {
        color: '#888888 !important'
      },
      '& span:not(.Mui-disabled)': {
        color: '#3987CF'
      }
    },
    relationLabel: {
      fontWeight: 700,
      color: '#1F364D !important'
    },
    relationContent: {
      paddingLeft: 42
    }
  }))