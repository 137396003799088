import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import debounce from 'lodash/debounce'

import { InputBaseProps } from '@material-ui/core/InputBase/InputBase'
import { ClickAwayListener, Grow, List, MenuItem, Paper } from '@material-ui/core'

import { BootstrapInput } from './BootstrapInput'

import { useDefaultMenuItemStyles } from '../hooks/defaultSelectStylesHook'

import { emDash } from '../common/stringUtility'

export type CustomSelectOption<T> = {
  value: T | undefined

  label: string
}

export type CustomSelectProps<T> = {
  value: T | undefined

  onChange: (value: T | undefined) => void | Promise<void>

  options: CustomSelectOption<T>[]

  fullWidth?: boolean

  placeholder?: string

  onInputChange?: (newSearchTerm: string) => void

  searchable?: boolean

  isLoading?: boolean

  withUnselectedValue?: boolean

  inputProps?: InputBaseProps
}

export const DefaultSelect =
  observer(
    <T extends unknown>
    ({
       value,
       onChange,
       options,
       fullWidth = false,
       placeholder = '',
       onInputChange,
       searchable = false,
       isLoading = false,
       withUnselectedValue = false,
       inputProps = {}
     }: CustomSelectProps<T>) => {
      const classes = useDefaultMenuItemStyles()
      const [open, setOpen] = useState(false)
      const [inputValue, setInputValue] = useState('')
      const [loadingSelect, setLoadingSelect] = useState(false)
      const anchorRef = useRef<HTMLDivElement>(null)

      // TODO: Why are we re-implementing all of this instead of styling MUI components? There are a lot of complications with these inputs.
      const handleInputChangeDebounced =
        useMemo(() => debounce(
            (newValue: string) => {
              if (searchable && onInputChange) {
                onInputChange(newValue)
              }
            }, 800),
          [onInputChange, searchable])

      useEffect(() => {
        if (!onInputChange) {
          const selectedOption = options.find(option => option.value === value)

          setInputValue(selectedOption?.label ?? emDash)
        }
      }, [value, options, onInputChange])

      const handleToggle =
        useCallback(() => {
          setOpen(prevOpen => !prevOpen)
        }, [])

      const handleClose =
        useCallback((event: React.MouseEvent<Document, MouseEvent>) => {
          if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
            return
          }
          setOpen(false)
        }, [])

      const handleSelect =
        useCallback(
          async (selectedValue: T | undefined) => {
            setLoadingSelect(true)

            try {
              await onChange(selectedValue)
            } finally {
              setLoadingSelect(false)
              setOpen(false)
            }
          },
          [onChange])

      const handleInputChange =
        useCallback(
          ({target: {value: newValue}}: React.ChangeEvent<HTMLInputElement>) => {
            setInputValue(newValue)
            handleInputChangeDebounced(newValue)
          },
          [handleInputChangeDebounced])

      const handleKeyDown =
        useCallback(
          ({key}: React.KeyboardEvent) => {
            if (key === 'Escape') {
              setOpen(false)
            } else if (!open && (key === 'ArrowDown' || key === 'Enter')) {
              setOpen(true)
            }
          },
          [open])

      const finalOptions = withUnselectedValue
                           ? [{value: undefined, label: emDash}, ...options]
                           : options

      return (
        <ClickAwayListener onClickAway={handleClose}>
          <div className={classes.inputWrapper} ref={anchorRef} style={fullWidth ? {width: '100%'} : {}}>
            <BootstrapInput
              fullWidth={fullWidth}
              value={inputValue}
              onClick={handleToggle}
              onKeyDown={handleKeyDown}
              onChange={searchable ? handleInputChange : undefined}
              readOnly={!searchable}
              placeholder={placeholder}
              className={classes.input}
              {...inputProps}
            />
            {open && (
              <Grow in={open} style={{transformOrigin: 'top'}}>
                <Paper className={classes.menu}>
                  <List component="nav">
                    {isLoading || loadingSelect ? (
                      <MenuItem className={classes.menuItem} disabled>
                        Загрузка...
                      </MenuItem>
                    ) : finalOptions.length === 0 ? (
                      <MenuItem className={classes.menuItem} disabled>
                        Нет доступных вариантов
                      </MenuItem>
                    ) : (
                          finalOptions.map((option, index) => (
                            <MenuItem
                              key={index}
                              className={classes.menuItem}
                              onClick={() => handleSelect(option.value)}
                            >
                              {option.label}
                            </MenuItem>
                          ))
                        )}
                  </List>
                </Paper>
              </Grow>
            )}
          </div>
        </ClickAwayListener>
      )
    })