//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { MyProductsInfoModel } from '../Models/MyProductsInfoModel';
import { NavState } from '../Models/NavState';
import { MyProductsTablePageModel } from '../Models/MyProductsTablePageModel';
import { MyProductsTablePageOptions } from '../Models/MyProductsTablePageOptions';
import { MyProductDiffModel } from '../Models/MyProductDiffModel';
import { OzonProductsTablePageModel } from '../Models/OzonProductsTablePageModel';
import { OzonProductsTablePageOptions } from '../Models/OzonProductsTablePageOptions';
import { WbProductsTablePageModel } from '../Models/WbProductsTablePageModel';
import { ProductsTablePageOptions } from '../Models/ProductsTablePageOptions';
import { YandexMarketProductsTablePageModel } from '../Models/YandexMarketProductsTablePageModel';
import { MoySkladProductsTablePageModel } from '../Models/MoySkladProductsTablePageModel';
import { TablePageOptionsBase } from '../Models/TablePageOptionsBase';
import { OzonProductModel } from '../Models/OzonProductModel';
import { SetExternalProductsModel } from '../Models/SetExternalProductsModel';
import { WbProductModel } from '../Models/WbProductModel';
import { YandexMarketProductModel } from '../Models/YandexMarketProductModel';
import { ExternalProductOption } from '../Models/ExternalProductOption';
import { GetExternalProductsModel } from '../Models/GetExternalProductsModel';
import { MyProductModel } from '../Models/MyProductModel';
import { StoreProductSyncModel } from '../Models/StoreProductSyncModel';
import { SetStoreProductsSyncModel } from '../Models/SetStoreProductsSyncModel';
import { IntegrationType } from '../Entity/IntegrationType';
import { UploadWbProductsFileResult } from '../Models/UploadWbProductsFileResult';

import { boundClass } from 'autobind-decorator';
import { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';
import { encodeUrlParameter, getAxiosData } from '../../../common/urlUtility';
@boundClass export class ProductController
{
  constructor (axios: AxiosInstance)
  {
    this.axios = axios
  }
  
  private readonly axios: AxiosInstance
  
  getMyInfo(navState: NavState, _config?: AxiosRequestConfig) : Promise<MyProductsInfoModel>
  {
    return this.axios.post<MyProductsInfoModel>(`Product/GetMyInfo`, navState, _config).then(getAxiosData)
  }
  

  getMyPage(options: MyProductsTablePageOptions, _config?: AxiosRequestConfig) : Promise<MyProductsTablePageModel>
  {
    return this.axios.post<MyProductsTablePageModel>(`Product/GetMyPage`, options, _config).then(getAxiosData)
  }
  

  getDiffs(_config?: AxiosRequestConfig) : Promise<MyProductDiffModel[]>
  {
    return this.axios.get<MyProductDiffModel[]>(`Product/GetDiffs`, _config).then(getAxiosData)
  }
  

  getOzonPage(options: OzonProductsTablePageOptions, _config?: AxiosRequestConfig) : Promise<OzonProductsTablePageModel>
  {
    return this.axios.post<OzonProductsTablePageModel>(`Product/GetOzonPage`, options, _config).then(getAxiosData)
  }
  

  getWbPage(options: ProductsTablePageOptions, _config?: AxiosRequestConfig) : Promise<WbProductsTablePageModel>
  {
    return this.axios.post<WbProductsTablePageModel>(`Product/GetWbPage`, options, _config).then(getAxiosData)
  }
  

  getYandexMarketPage(options: ProductsTablePageOptions, _config?: AxiosRequestConfig) : Promise<YandexMarketProductsTablePageModel>
  {
    return this.axios.post<YandexMarketProductsTablePageModel>(`Product/GetYandexMarketPage`, options, _config).then(getAxiosData)
  }
  

  getMoySkladPage(options: TablePageOptionsBase, _config?: AxiosRequestConfig) : Promise<MoySkladProductsTablePageModel>
  {
    return this.axios.post<MoySkladProductsTablePageModel>(`Product/GetMoySkladPage`, options, _config).then(getAxiosData)
  }
  

  setOzonProducts(model: SetExternalProductsModel, _config?: AxiosRequestConfig) : Promise<OzonProductModel[]>
  {
    return this.axios.post<OzonProductModel[]>(`Product/SetOzonProducts`, model, _config).then(getAxiosData)
  }
  

  setWbProducts(model: SetExternalProductsModel, _config?: AxiosRequestConfig) : Promise<WbProductModel[]>
  {
    return this.axios.post<WbProductModel[]>(`Product/SetWbProducts`, model, _config).then(getAxiosData)
  }
  

  setYandexMarketProducts(model: SetExternalProductsModel, _config?: AxiosRequestConfig) : Promise<YandexMarketProductModel[]>
  {
    return this.axios.post<YandexMarketProductModel[]>(`Product/SetYandexMarketProducts`, model, _config).then(getAxiosData)
  }
  

  getOzonProductOptions(model: GetExternalProductsModel, _config?: AxiosRequestConfig) : Promise<ExternalProductOption[]>
  {
    return this.axios.post<ExternalProductOption[]>(`Product/GetOzonProductOptions`, model, _config).then(getAxiosData)
  }
  

  getWbProductOptions(model: GetExternalProductsModel, _config?: AxiosRequestConfig) : Promise<ExternalProductOption[]>
  {
    return this.axios.post<ExternalProductOption[]>(`Product/GetWbProductOptions`, model, _config).then(getAxiosData)
  }
  

  getYandexMarketProductOptions(model: GetExternalProductsModel, _config?: AxiosRequestConfig) : Promise<ExternalProductOption[]>
  {
    return this.axios.post<ExternalProductOption[]>(`Product/GetYandexMarketProductOptions`, model, _config).then(getAxiosData)
  }
  

  setOzonSync(model: StoreProductSyncModel, _config?: AxiosRequestConfig) : Promise<MyProductModel>
  {
    return this.axios.post<MyProductModel>(`Product/SetOzonSync`, model, _config).then(getAxiosData)
  }
  

  setWbSync(model: StoreProductSyncModel, _config?: AxiosRequestConfig) : Promise<MyProductModel>
  {
    return this.axios.post<MyProductModel>(`Product/SetWbSync`, model, _config).then(getAxiosData)
  }
  

  setYandexMarketSync(model: StoreProductSyncModel, _config?: AxiosRequestConfig) : Promise<MyProductModel>
  {
    return this.axios.post<MyProductModel>(`Product/SetYandexMarketSync`, model, _config).then(getAxiosData)
  }
  

  setOzonSyncMultiple(model: SetStoreProductsSyncModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`Product/SetOzonSyncMultiple`, model, _config).then(getAxiosData)
  }
  

  setWbSyncMultiple(model: SetStoreProductsSyncModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`Product/SetWbSyncMultiple`, model, _config).then(getAxiosData)
  }
  

  setYandexMarketSyncMultiple(model: SetStoreProductsSyncModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`Product/SetYandexMarketSyncMultiple`, model, _config).then(getAxiosData)
  }
  

  exportAllToXls(integrationType: IntegrationType, accountId?: number, _config?: AxiosRequestConfig) : Promise<AxiosResponse<unknown>>
  {
    return this.axios.get<unknown/*Microsoft.AspNetCore.Mvc.FileContentResult*/>(`Product/ExportAllToXls/${encodeUrlParameter(integrationType)}/${encodeUrlParameter(accountId)}`, _config)
  }
  

  uploadWbProducts(wbAccountId: number, productsFile: File, _config?: AxiosRequestConfig) : Promise<UploadWbProductsFileResult>
  {
    const formData = new FormData()
    formData.append('productsFile', productsFile)
    
    return this.axios.post<UploadWbProductsFileResult>(
      `Product/UploadWbProducts/${encodeUrlParameter(wbAccountId)}`,
      formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        ..._config
      }).then(getAxiosData)
  }
  

  /** Gets the full relative URL for the action */
  getDiffsUrl(_config?: AxiosRequestConfig) : string
  {
    return `/api/Product/GetDiffs`
  }
  

  /** Gets the full relative URL for the action */
  exportAllToXlsUrl(integrationType: IntegrationType, accountId?: number, _config?: AxiosRequestConfig) : string
  {
    return `/api/Product/ExportAllToXls/${encodeUrlParameter(integrationType)}/${encodeUrlParameter(accountId)}`
  }
  

}
