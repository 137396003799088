import React, { useMemo } from 'react'
import { matchPath, NavLink, useHistory } from 'react-router-dom'
import { useLogic, useStore } from '../hooks/storeHook'
import { observer } from 'mobx-react-lite'

import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core'

import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import { AccountSyncSwitch } from './AccountSyncSwitch'

import { StoreAccountModelBase } from '../server/mpsklad_core/Models/StoreAccountModelBase'
import { accountNavigationLinks, appRoutes } from '../common/appRoutes'

import productsIcon from '../assets/products.svg'
import ordersIcon from '../assets/order.svg'
import settingsIcon from '../assets/setting.svg'
import printIcon from '../assets/print.svg'
import importIcon from '../assets/import.svg'
import logsIcon from '../assets/logs.svg'
import removeAccountIcon from '../assets/cross.svg'
import { useMessages } from '../hooks/snackbarHooks'
import { getIntegrationTypeKey } from '../types/integrationTypeUtils'

export type AccountNavigationProps = {
  isSelected: boolean;
  account: StoreAccountModelBase;
  hasMoySkladApp: boolean;
  onAccountChange: (accountIndex: number) => void;
  accountIndex: number;
};

const tabData = {
  [appRoutes.CommonStore.products.template]: {imgSource: productsIcon, text: 'Товары'},
  [appRoutes.CommonStore.orders.template]: {imgSource: ordersIcon, text: 'Заказы'},
  [appRoutes.CommonStore.settings.template]: {imgSource: settingsIcon, text: 'Настройка'},
  [appRoutes.CommonStore.print.template]: {imgSource: printIcon, text: 'Печать'},
  [appRoutes.CommonStore.import.template]: {imgSource: importIcon, text: 'Импорт'},
  [appRoutes.CommonStore.syncLogs.template]: {imgSource: logsIcon, text: 'Логи'}
}

export const AccountNavigation =
  observer(
    ({
       isSelected,
       account,
       onAccountChange,
       accountIndex
     }: AccountNavigationProps) => {

      const logic = useLogic()
      const {homeNavRequired, setHomeNav} = useStore()
      const history = useHistory()
      const location = history.location

      const {showSuccess, showError} = useMessages()

      const classes = useStyles()

      const currentLinks = useMemo(() =>
          accountNavigationLinks(account.integrationType, account.id),
        [account.id, account.integrationType])

      const onDeleteAccount = async () => {
        try {
          await logic.deleteAccount(account.id)
          setHomeNav(homeNavRequired.integrationType, undefined)
          const integrationTypeKey = getIntegrationTypeKey(homeNavRequired.integrationType)
          history.push(appRoutes.CommonStore.settings.api.route({accountId: 0, integration: integrationTypeKey}))
          showSuccess('Аккаунт удален!')
        } catch (e) {
          showError('Произошла ошибка во время удаления аккаунта...')
        }
      }

      return (
        <Box className={clsx('nav_account', isSelected && 'nav_account_selected')}>
          <Box
            className={classes.accountInfo}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onClick={() => onAccountChange(accountIndex)}
            aria-disabled={isSelected}>
            <Typography className={classes.accountName}>{account.name}</Typography>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <img className={classes.deleteIcon} src={removeAccountIcon} alt={'Удаление аккаунта'}
                   onClick={onDeleteAccount}/>
              <AccountSyncSwitch account={account} setSync={logic.getSetAccountSyncFunc(account.integrationType)}/>
            </Box>
          </Box>
          {isSelected && (
            <Box className="nav_account_actions">
              <List>
                {currentLinks.map((link, index) => {
                  const {imgSource, text} = tabData[link.template]
                  const isSelected = matchPath(location.pathname, {path: link.template})

                  return (
                    <ListItem
                      key={index}
                      component={NavLink}
                      to={link.route}
                      className={clsx(
                        'nav_account_link',
                        isSelected && 'nav_account_link_selected',
                        classes.listItem)}
                      button
                    >
                      <ListItemIcon
                        className={
                          clsx(
                            classes.listItemIcon,
                            'nav_account_link',
                            isSelected && 'nav_account_link_selected')}>
                        <img src={imgSource} alt={text}/>
                      </ListItemIcon>

                      <ListItemText
                        className={
                          clsx(
                            classes.listItemText,
                            'nav_account_link',
                            isSelected && classes.listItemTextSelected)}
                        primary={text}
                      />
                    </ListItem>
                  )
                })}

              </List>
            </Box>
          )}
        </Box>
      )
    })

const useStyles = makeStyles(() => ({
  accountName: {
    fontFamily: 'Roboto Regular',
    fontSize: 14,
    fontWeight: 700
  },
  accountInfo: {
    padding: '10px 10px 10px 22px',
    cursor: 'pointer'
  },
  listItemIcon: {
    minWidth: 30
  },
  listItem: {
    padding: '5px 0 0 10px',
    '&:hover': {
      backgroundColor: '#FFF'
    }
  },
  listItemText: {
    '& span': {
      fontFamily: 'Roboto Regular',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '14.4px'
    }
  },
  listItemTextSelected: {
    '& span': {
      color: '#3987CF',
      fontFamily: 'Roboto Regular',
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '14.4px'
    }
  },
  deleteIcon: {
    width: '12px'
  }
}))