import { useState } from 'react'

import { YandexMarketAccountModel } from '../../../../server/mpsklad_core/Models/YandexMarketAccountModel'
import { YandexMarketDeliveryType } from '../../../../server/mpsklad_core/Entity/YandexMarket/Enums/YandexMarketDeliveryType'

export const useYandexMarketForm = (initialAccount: YandexMarketAccountModel | undefined) => {
  const [campaignId, setCampaignId] = useState(initialAccount?.campaignId?.toString() ?? '')
  const [businessId, setBusinessId] = useState(initialAccount?.businessId?.toString() ?? '')
  const [userCode, setUserCode] = useState('')
  const [name, setName] = useState(initialAccount?.name ?? '')
  const [usingUnifiedPrices, setUsingUnifiedPrices] = useState(initialAccount?.usingUnifiedPrices ?? false)
  const [deliveryType, setDeliveryType] = useState(initialAccount?.deliveryType ?? YandexMarketDeliveryType.FBS)
  const [isLoading, setIsLoading] = useState(false)

  const toggleUsingUnifiedPrices = () => setUsingUnifiedPrices((prev) => !prev)

  return {
    campaignId,
    setCampaignId,
    businessId,
    setBusinessId,
    userCode,
    setUserCode,
    name,
    setName,
    usingUnifiedPrices,
    toggleUsingUnifiedPrices,
    deliveryType,
    setDeliveryType,
    isLoading,
    setIsLoading
  }
}