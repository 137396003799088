import React from 'react'

import { RadioGroup, RadioGroupProps } from '@material-ui/core'
import { FormControlLabelProps } from '@material-ui/core/FormControlLabel/FormControlLabel'

import { DefaultRadioItem, DefaultRadioItemProps, getOptionKey } from './DefaultRadioItem'

import { Replace } from '../common/tsUtils'
import { required } from '../common/objectUtils'

export type DefaultRadioValue = number | boolean

export type DefaultRadioOption<TValue extends DefaultRadioValue> = {
  value: TValue

  label: string

  hint?: string

  disabled?: boolean
}

export type DefaultRadioGroupProps<TValue extends DefaultRadioValue> =
  Replace<RadioGroupProps, {
    value: TValue

    /**
     * The {@link newValue} parameter preserves reference equality with the {@link value}.
     * @param value
     */
    onChange: (newValue: TValue) => void
  }> & {
  disabled?: boolean

  options: ReadonlyArray<DefaultRadioOption<TValue>>

  optionProps?: Omit<FormControlLabelProps, 'disabled' | 'value' | 'className' | 'label' | 'control'>

  optionClassName?: string

  optionRadioClassName?: string

  optionLabelClassName?: string

  optionHintClassName?: string

  getOptionClassName?: (checked: boolean) => string

  getOptionLabelClassName?: (checked: boolean) => string
}

export const DefaultRadioGroup =
  <TValue extends DefaultRadioValue>
  ({
     disabled, value, options,
     onChange,
     optionProps, optionClassName,
     optionRadioClassName, optionLabelClassName, optionHintClassName,
     getOptionClassName, getOptionLabelClassName,
     ...passProps
   }: DefaultRadioGroupProps<TValue>) => {
    const selectedOption = required(options.find(_ => _.value === value))

    // All of option/key mapping is needed to preserve reference equality of options/value in onChange
    const onInternalChange: RadioGroupProps['onChange'] =
      ({target: {value: newOptionKey}}) => {
        if (!disabled) {
          onChange(required(options.find(_ => getOptionKey(_) === newOptionKey)).value)
        }
      }

    const commonOptionProps = {
      value,
      disabled,
      optionClassName,
      getOptionClassName,
      optionRadioClassName,
      optionLabelClassName,
      getOptionLabelClassName,
      optionHintClassName,
      ...optionProps
    } satisfies Omit<DefaultRadioItemProps<TValue>, 'optionKey' | 'option'>

    return (
      <RadioGroup
        value={getOptionKey(selectedOption)}
        onChange={onInternalChange}
        {...passProps}>
        {
          options.map(
            option =>
              <DefaultRadioItem
                key={getOptionKey(option)}
                option={option}
                {...commonOptionProps}
              />
          )
        }
      </RadioGroup>
    )
  }