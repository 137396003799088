import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Box } from '@material-ui/core'
import { Redirect, Route, Switch, RouteComponentProps } from 'react-router-dom'

import { useLogic, useStore } from '../../../hooks/storeHook'
import { appRoutes, StoreRouteParams } from '../../../common/appRoutes'
import { RoutingTabs } from '../../RoutingTabs'

import { WildberriesLabelsPrint } from './WildberriesLabelsPrint'
import { WbFilesTab } from '../../WbFilesTab'
import { required } from '../../../common/objectUtils'
import { RouteTabItem } from '../../RouteTabItem'

export const WildberriesPrintPage = observer(() => {
  const logic = useLogic()
  const {homeNavRequired} = useStore()

  const account = logic.tryGetWbAccount(homeNavRequired.accountId)

  if (!account) {
    return (
      <Box padding={3}>
        Аккаунт не найден
      </Box>
    )
  }

  if (!account?.isFBS) {
    return (
      <Box padding={3}>
        Печать этикеток недоступна при схеме работы FBW.
      </Box>
    )
  }

  const routes: RouteTabItem[] = useMemo(() => {
    const params: StoreRouteParams = {
      accountId: homeNavRequired.accountId,
      integration: 'Wildberries'
    }

    return [
      {
        title: 'Этикетки',
        route: 'labels',
        path: appRoutes.CommonStore.print.labels.route(params),
        template: appRoutes.CommonStore.print.labels.template,
        render: (props: RouteComponentProps) => (
          <WildberriesLabelsPrint account={account} {...props} />
        )
      },
      {
        title: 'Файлы',
        route: 'files',
        path: appRoutes.CommonStore.print.files.route(params),
        template: appRoutes.CommonStore.print.files.template,
        render: (props: RouteComponentProps) => (
          <WbFilesTab wbAccountId={account.id} {...props} />
        )
      }
    ]
  }, [account, homeNavRequired.accountId])

  const firstRoutePath = required(routes[0].path)

  return (
    <RoutingTabs routeTabs={routes}>
      <Switch>
        <Route exact path={appRoutes.CommonStore.print.template}>
          <Redirect to={firstRoutePath}/>
        </Route>
        {routes.map(({template, render}, index) => (
          <Route key={index} path={template} render={render}/>
        ))}
      </Switch>
    </RoutingTabs>
  )
})