import { useState } from 'react'

import { OzonAccountModel } from '../../../../server/mpsklad_core/Models/OzonAccountModel'
import { OzonDeliveryType } from '../../../../server/mpsklad_core/Entity/Ozon/OzonDeliveryType'

export const useOzonForm =
  (account: OzonAccountModel | undefined) => {
  const [isLoading, setIsLoading] = useState(false)
  const [deliveryType, setDeliveryType] = useState(account?.deliveryType ?? OzonDeliveryType.FBS)
  const [apiKey, setApiKey] = useState(account?.apiKey ?? '')
  const [clientId, setClientId] = useState(account?.clientId?.toString() ?? '')
  const [name, setName] = useState(account?.name ?? '')

  return {
    isLoading,
    setIsLoading,
    deliveryType,
    setDeliveryType,
    apiKey,
    setApiKey,
    clientId,
    setClientId,
    name,
    setName
  }
}