import React from 'react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'
import { clamp } from 'lodash'

import { Box, FormHelperText, FormLabel, Grid, IconButton, InputAdornment, OutlinedInput } from '@material-ui/core'

import addIcon from '../assets/addIcon.svg'
import arrowRight from '../assets/arrowRight.svg'
import deleteIcon from '../assets/deleteIcon.svg'

import { BootstrapInput } from './BootstrapInput'
import { WarehousePicker } from './WarehousePicker'
import { MoySkladStorePicker } from './MoySkladStorePicker'
import { StoresFormBaseProps, useStoresFormStyles } from './StoresFormDirect'

import { useLogic } from '../hooks/storeHook'
import { useMessages } from '../hooks/snackbarHooks'
import { useDefaultMenuItemStyles } from '../hooks/defaultSelectStylesHook'

import { required } from '../common/objectUtils'

import { getIntegrationTypeName } from '../types/navStore'

import { EditStoreModel } from '../server/mpsklad_core/Models/EditStoreModel'
import { WarehouseModel } from '../server/mpsklad_core/Models/WarehouseModel'
import { EditStoresModel } from '../server/mpsklad_core/Models/EditStoresModel'
import { MoySkladStoreModel } from '../server/mpsklad_core/Models/MoySkladStoreModel'

/**
 * See {@link StoreRelationType.SplitFromMoySklad}.
 */
export const StoresFormSplitFromMoySklad =
  observer(
    <TWarehouse extends WarehouseModel>
    ({
       disabled,
       storeType,
       serverData, formData,
       whKeySelector, storeWhKeySelector,
       formatWarehouseDisplayName
     }: StoresFormBaseProps<TWarehouse>) => {
      const classes = useStoresFormStyles()
      const menuClasses = useDefaultMenuItemStyles()

      const {showWarning} = useMessages()

      const {showDialog} = useLogic()

      const storeTypeName = getIntegrationTypeName(storeType)

      if (serverData.warehouses.length === 0) {
        return <FormHelperText error>Нет складов {storeTypeName}.</FormHelperText>
      }

      if (serverData.msStores.length === 0) {
        return <FormHelperText error>Нет складов МоегоСклада.</FormHelperText>
      }

      const msStoreId = tryGetMsStoreId(formData)

      const unmatchedWarehouse = serverData.warehouses.find(
        warehouse => formData.stores.every(store => storeWhKeySelector(store) !== whKeySelector(warehouse)))

      const onAddStore =
        () => {
          if (!unmatchedWarehouse) {
            showWarning(`Все склады ${storeTypeName} сопоставлены!`)
            return
          }

          formData.stores.push({
            storeId: undefined,
            warehouseId: unmatchedWarehouse.id,
            warehouseName: unmatchedWarehouse.name,
            msStoreIds: msStoreId ? [msStoreId] : [/* Allow import into MS */],
            stocksSplitPercentage: 0
          })
        }

      const onDeleteStore =
        async (formStore: EditStoreModel) => {
          if (formStore.storeId != null && !await showDialog(
            `Заказы для склада ${storeTypeName} будут удалены из MPsklad после сохранения!`, {
              title: 'Удалить сопоставление складов?',
              acceptButton: 'Удалить'
            })) {
            return
          }

          formData.stores = formData.stores.filter(_ => _ !== formStore)
        }

      const totalSplitPercentage = formData.stores.filter(_ => _.stocksSplitPercentage != null)
                                           .map(_ => required(_.stocksSplitPercentage))
                                           .reduce((total, current) => total + current, 0)

      const availableSplitPercentage = Math.max(0, Math.min(100, 100 - totalSplitPercentage))

      const onChangePercentage =
        (formStore: EditStoreModel, newValue: string) => {
          if (!newValue) {
            formStore.stocksSplitPercentage = undefined
            return
          }

          let newValueParsed = parseInt(newValue)

          if (isNaN(newValueParsed)) {
            showWarning(`Невалидное значение процентов: ${newValue}`)
            formStore.stocksSplitPercentage = undefined
            return
          }

          newValueParsed = clamp(newValueParsed, 0, 100)

          const availablePercentageForStore = availableSplitPercentage + (formStore.stocksSplitPercentage ?? 0)

          if (newValueParsed > availablePercentageForStore) {
            showWarning(`Невалидная сумма процентов, доступно: ${availablePercentageForStore}%`)
            formStore.stocksSplitPercentage = availablePercentageForStore
            return
          }

          formStore.stocksSplitPercentage = newValueParsed
        }

      const onChangeWarehouse =
        (formStore: EditStoreModel, warehouse: TWarehouse) => {
          formStore.warehouseId = warehouse.id
          formStore.warehouseName = warehouse.name
        }

      const onChangeMsStore =
        (newMsStore: MoySkladStoreModel | null) => {
          for (const formStore of formData.stores) {
            formStore.msStoreIds = newMsStore ? [newMsStore.id] : []
          }
        }

      return (
        <Grid container direction="column">
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={4}>
                <FormLabel component="legend" className={classes.label}>
                  <span className={classes.dash}>&mdash;</span>
                  Склад МоегоСклада
                </FormLabel>
              </Grid>

              <Grid item xs={6}>
                <FormLabel component="legend" className={classes.label}>
                  <span className={classes.dash}>&mdash;</span>
                  Склад {storeTypeName}
                </FormLabel>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container alignItems="center">
              <Grid item xs={3}>
                <MoySkladStorePicker
                  allowEmpty
                  filterMatchedStores
                  disabled={formData.stores.length === 0}
                  msStoreId={msStoreId}
                  msStores={serverData.msStores}
                  formData={formData}
                  onChange={onChangeMsStore}
                />
              </Grid>

              <Grid item xs={1}>
                <Box display="flex" height="100%" justifyContent="center" alignItems="center">
                  <img src={arrowRight} alt="" style={{width: 20, height: 20}}/>
                </Box>
              </Grid>

              <Grid item xs={5}>
                <Grid container spacing={3}>
                  {
                    formData.stores.map(
                      (formStore, index) =>
                        <Grid item xs={12} key={index}>
                          <Grid container spacing={1}>
                            <Grid item xs={2}>
                              <OutlinedInput
                                type="number"
                                inputProps={{
                                  min: 0,
                                  max: (formStore.stocksSplitPercentage ?? 0) + availableSplitPercentage
                                }}
                                value={formStore.stocksSplitPercentage ?? ''}
                                onChange={e => onChangePercentage(formStore, e.target.value)}
                                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                style={{maxWidth: 94, maxHeight: 40, borderRadius: 8}}
                              />
                            </Grid>

                            <Grid item xs={8}>
                              <WarehousePicker
                                formStore={formStore}
                                formData={formData}
                                warehouses={serverData.warehouses}
                                whKeySelector={whKeySelector}
                                storeWhKeySelector={storeWhKeySelector}
                                formatWarehouseDisplayName={formatWarehouseDisplayName}
                                onChange={warehouse => onChangeWarehouse(formStore, warehouse)}
                              />
                            </Grid>

                            <Grid item xs={2}>
                              <IconButton
                                title="Удалить"
                                disabled={disabled}
                                className={clsx(menuClasses.iconButtonOutlined)}
                                onClick={() => onDeleteStore(formStore)}
                              >
                                <img src={deleteIcon} alt=""/>
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                    )
                  }

                  {
                    !!unmatchedWarehouse &&
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={10}>
                          <BootstrapInput readOnly className={menuClasses.input} onClick={onAddStore}/>
                        </Grid>

                        <Grid item xs={2}>
                          <IconButton
                            title="Добавить"
                            disabled={disabled}
                            className={clsx(menuClasses.iconButtonOutlined)}
                            onClick={onAddStore}
                          >
                            <img src={addIcon} alt=""/>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={4}>
                {/* spacer */}
              </Grid>

              <Grid item xs={3}>
                <Box marginTop={2}>
                  <FormHelperText error={totalSplitPercentage !== 100}>
                    Сумма: {totalSplitPercentage}%
                    {
                      totalSplitPercentage !== 100 &&
                      <span>
                        <br/>
                        (должна быть 100%)
                      </span>
                    }
                  </FormHelperText>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    })

const tryGetMsStoreId =
  (formData: EditStoresModel): string | undefined => {
    const msStoreIds = new Set(formData.stores.flatMap(_ => _.msStoreIds))

    if (msStoreIds.size === 0) {
      return undefined
    }

    if (msStoreIds.size === 1) {
      return Array.from(msStoreIds)[0]
    }

    throw new Error(`Bad MS store count for SplitFromMoySklad: ${msStoreIds.size}.`)
  }