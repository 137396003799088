import React from 'react'
import { observer } from 'mobx-react-lite'

import { AccountStoresForm } from './AccountStoresForm'

import { useApi } from '../hooks/storeHook'

import { required } from '../common/objectUtils'

import { WbAccountRequiredProps } from '../types/accountProps'
import { WbDeliveryType } from '../server/mpsklad_core/Entity/Wildberries/WbDeliveryType'

export const WbStoresForm =
  observer(({account}: WbAccountRequiredProps) => {
    const api = useApi()

    return <AccountStoresForm
      account={account}
      loadStores={api.userSync.getWbStores}
      loadWarehouses={api.userSync.getWbWarehouses}
      editStores={api.userSync.editWbStores}
      whKeySelector={account.isFBS ? warehouse => required(warehouse.id) : warehouse => required(warehouse.name)}
      storeWhKeySelector={account.isFBS ? store => required(store.warehouseId) : store => required(store.warehouseName)}
      hint={account.isFBS ? undefined : 'На складах со звёздочкой * есть остатки'}
      formatWarehouseNameHint={warehouse => warehouse.hasStocks ? ' *' : ''}
      loadingHelpText={
        account.deliveryType === WbDeliveryType.FBW
        ? 'Загрузка складов FBW может занять пару минут (ограничение API WB).'
        : undefined
      }
    />
  })