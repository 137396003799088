import React, { FormEvent, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import { Box, FormHelperText, FormLabel, Grid, Typography } from '@material-ui/core'

import { IOSSwitch } from '../../IOSSwitch'
import { DefaultRadioGroup } from '../../DefaultRadioGroup'

import { useMessages } from '../../../hooks/snackbarHooks'
import { useApi, useLogic, useStore } from '../../../hooks/storeHook'
import { useYandexMarketForm } from './hooks/useYandexMarketForm'
import { useRadioGroupStyles } from '../../../hooks/radioGroupStylesHook'

import { CommissionsRecordingType } from '../../../server/mpsklad_core/Entity/Base/CommissionsRecordingType'
import { EditYandexMarketAccountModel } from '../../../server/mpsklad_core/Models/EditYandexMarketAccountModel'
import { YandexMarketDeliveryType } from '../../../server/mpsklad_core/Entity/YandexMarket/Enums/YandexMarketDeliveryType'
import { useHistory } from 'react-router-dom'
import { useSettingsPageStyles } from '../../../hooks/settingsPageStylesHook'
import { appRoutes } from '../../../common/appRoutes'
import { BootstrapInput } from '../../BootstrapInput'
import { SyncStartTableRow } from '../../SyncStartTableRow'
import { YandexMarketLoadOrdersForm } from '../../YandexMarketLoadOrdersForm'

export const YandexMarketApiSettings =
  observer(
    () => {
      const classes = {...useSettingsPageStyles(), ...useStyles()}
      const radioGroupClasses = useRadioGroupStyles()

      const getDeliveryTypeClasses =
        (checked: boolean) => clsx(radioGroupClasses.radioButton, checked && radioGroupClasses.selectedRadioButton)

      const {showSuccess, showError} = useMessages()

      const logic = useLogic()
      const store = useStore()

      const account = logic.tryGetYandexMarketAccount(store.homeNavRequired.accountId)
      const history = useHistory()
      const {
        userSync: {
          setYandexMarketSyncStartDate
        }
      } = useApi()
      const {
        campaignId,
        setCampaignId,
        businessId,
        setBusinessId,
        userCode,
        setUserCode,
        name,
        setName,
        usingUnifiedPrices,
        toggleUsingUnifiedPrices,
        deliveryType,
        setDeliveryType,
        isLoading,
        setIsLoading
      } = useYandexMarketForm(account)

      const deliveryTypeOptions = useMemo(() => [{
        value: YandexMarketDeliveryType.FBY,
        label: 'Продажа со склада Yandex.Market (FBY)'
      }, {
        value: YandexMarketDeliveryType.FBS,
        label: 'Продажа со своего склада (FBS)'
      }, {
        value: YandexMarketDeliveryType.Express,
        label: 'Продажа со своего склада с доставкой силами Yandex.Market (Express)'
      }, {
        value: YandexMarketDeliveryType.DBS,
        label: 'Продажа со своего склада и доставка силами продавца (DBS)',
        disabled: true
      }], [])

      const onSubmit = async (e: FormEvent) => {
        e.preventDefault()

        if (!userCode) {
          showError('Необходимо ввести код подтверждения.')
          return
        }

        setIsLoading(true)

        try {
          const formData = {
            campaignId: parseInt(campaignId),
            userCode: parseInt(userCode),
            usingUnifiedPrices,
            deliveryType,
            isUpdatePrices: account?.isUpdatePrices ?? false,
            isGeneratingPayments: account?.isGeneratingPayments ?? false,
            isUsingCommissions: account?.isUsingCommissions ?? false,
            ordersCommissionsRecording: account?.ordersCommissionsRecording ?? CommissionsRecordingType.Nothing,
            documentsCommissionsRecording: account?.documentsCommissionsRecording ?? CommissionsRecordingType.Nothing,
            name: name || undefined,
            msSalesReturnStoreId: account?.msSalesReturnStoreId,
            msData: {
              msOrganization: account?.msOrganization ?? '',
              msCounterparty: account?.msCounterparty ?? '',
              msContractId: account?.msContractId ?? undefined,
              msSalesChannelId: account?.msSalesChannelId ?? undefined,
              msProjectId: account?.msProjectId ?? undefined
            }
          }satisfies Omit<EditYandexMarketAccountModel, 'id'>

          if (account) {
            await logic.editYandexMarketAccount({id: account.id, ...formData})
          } else {
            const newAccount = await logic.createYandexMarketAccount({id: 0, ...formData})

            history.push(
              appRoutes.CommonStore.settings.api.route({accountId: newAccount.id, integration: 'YandexMarket'}))

          }

          store.syncStore.setYandexMarketUserCode(parseInt(userCode))
          showSuccess('Сохранено!')
        } catch (e) {
          console.error('Failed to save YandexMarket account', e)
          showError('Ошибка при сохранении аккаунта')
        } finally {
          setIsLoading(false)
        }
      }

      return (
        <Box className={classes.pageContainer}>
          <Typography className={classes.header}>Настройки API</Typography>
          <form onSubmit={onSubmit}>
            <Grid container spacing={3}>
              {/* Left column */}
              <Grid item xs={12} md={6} style={account ? {minWidth: '50%'} : {}}>
                <Grid container spacing={3}>
                  {/* Campaign ID, Business ID and User Code in one row */}
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                          <Grid item className={classes.root} xs={12}>
                            <Box>
                              <FormLabel component="legend" className={classes.label}>
                                <span className={classes.dash}>—</span>Номер компании
                                <span className={classes.star}>*</span>
                              </FormLabel>
                            </Box>

                            <BootstrapInput
                              required
                              id="campaignId"
                              placeholder="1337"
                              value={campaignId}
                              onChange={(e) => setCampaignId(e.target.value)}
                              fullWidth
                            />
                          </Grid>

                          <Grid item className={classes.root} xs={12}>
                            <Box>
                              <FormLabel component="legend" className={classes.label}>
                                <span className={classes.dash}>—</span>Идентификатор бизнеса
                              </FormLabel>
                            </Box>

                            <BootstrapInput
                              disabled
                              className={classes.textField}
                              id="bussinesId"
                              value={businessId === ''
                                     ? 'Значение появится при успешном добавлении аккаунта'
                                     : businessId}
                              onChange={(e) => setBusinessId(e.target.value)}
                              fullWidth
                            />
                          </Grid>

                          <Grid item className={classes.root} xs={12}>
                            <Box>
                              <FormLabel component="legend" className={classes.label}>
                                <span className={classes.dash}>—</span>Код подтверждения
                                <span className={classes.star}>*</span>
                              </FormLabel>
                            </Box>

                            <BootstrapInput
                              required
                              id="userCode"
                              placeholder="12228"
                              value={userCode}
                              onChange={(e) => setUserCode(e.target.value)}
                              fullWidth
                            />

                            <FormHelperText className={classes.helperText}>
                              Чтобы получить код, перейдите по данной <a
                              href="https://oauth.yandex.ru/authorize?response_type=code&client_id=bf8796a163424ce2b6ee8124a3a84a5b"
                              target="_blank" rel="noopener noreferrer">ссылке</a>.
                              Код активен 10 минут, после чего генерировать его придётся снова.
                            </FormHelperText>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Account Name */}
                  <Grid item xs={12}>
                    <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                      <Grid item className={classes.root} xs={12}>
                        <Box>
                          <FormLabel component="legend" className={classes.label}>
                            <span className={classes.dash}>—</span>Название аккаунта
                          </FormLabel>
                        </Box>

                        <BootstrapInput
                          required
                          id="campaignId"
                          placeholder="Yandex Market 1000"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Schema of Work */}
                  <Grid item xs={12}>
                    <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                      <Grid item className={classes.root} xs={12}>
                        <Box>
                          <FormLabel component="legend" className={classes.label}>
                            <span className={classes.dash}>—</span>Схема работы
                          </FormLabel>
                        </Box>

                        <DefaultRadioGroup
                          name="ModelType"
                          value={deliveryType}
                          options={deliveryTypeOptions}
                          onChange={setDeliveryType}
                          getOptionClassName={getDeliveryTypeClasses}
                          optionLabelClassName={radioGroupClasses.radioLabel}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {account &&
                   <Grid item xs={12}>
                     <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                       <Grid item className={classes.root} xs={12}>
                         <Box>
                           <FormLabel component="legend" className={classes.label}>
                             <span className={classes.dash}>—</span>
                             Настройка работ с ценами
                           </FormLabel>
                         </Box>

                         <Box width={'100%'} display="flex" justifyContent="space-between" alignItems="center">
                           <FormLabel className={classes.unifiedPricesLabel}>
                             Использовать общие цены
                           </FormLabel>
                           <IOSSwitch checked={usingUnifiedPrices} onChange={toggleUsingUnifiedPrices}/>
                         </Box>


                         <FormHelperText className={classes.helperText}>
                           Если ваша кампания использует разные цены в разных магазинах (стандартно для
                           давно зарегистрированных магазинов), то пропустите этот этап.
                         </FormHelperText>
                       </Grid>
                     </Grid>
                   </Grid>
                  }
                </Grid>
              </Grid>

              {/* Right column */}
              {account &&
               <Grid item xs={12} md={6}>
                 <Grid container spacing={3}>
                   {account.ordersTrackStartUnix &&
                    <Grid item xs={12}>
                      <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                        <Grid item className={classes.root} xs={12}>
                          <Box>
                            <FormLabel component="legend" className={classes.boldLabel}>
                              Дата начала синхронизации заказов
                            </FormLabel>
                          </Box>

                          <SyncStartTableRow account={account} onSubmit={setYandexMarketSyncStartDate}/>

                          <FormHelperText className={classes.helperText}>
                            Будут синхронизированы заказы, созданные позже этой даты.
                          </FormHelperText>
                        </Grid>
                      </Grid>
                    </Grid>
                   }

                   <Grid item xs={12}>
                     <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                       <Grid item className={classes.root} xs={12}>
                         <Box>
                           <FormLabel component="legend" className={classes.boldLabel}>
                             Загрузка заказов
                           </FormLabel>
                         </Box>

                         <Grid container spacing={2}>
                           <Grid item xs={12}>
                             <YandexMarketLoadOrdersForm yandexMarketAccountId={account.id}/>
                           </Grid>
                         </Grid>
                       </Grid>
                     </Grid>
                   </Grid>
                 </Grid>
               </Grid>
              }
            </Grid>

            <Box display="flex">
              <button
                className={clsx(classes.saveButton, 'default-button')}
                disabled={isLoading}
                onClick={onSubmit}
              >
                <p>{isLoading ? 'Сохраняем...' : 'Сохранить'}</p>
              </button>
            </Box>
          </form>
        </Box>
      )
    })

const useStyles = makeStyles((theme) => ({
  textField: {
    '& .Mui-disabled': {
      backgroundColor: '#888888',
      opacity: 0.2,
      color: theme.palette.mpSkladSecondary.darkBlue1
    }
  }
}))