import { integrationTypeNames } from './integrationTypeUtils'

import { Replace } from '../common/tsUtils'

import { NavState } from '../server/mpsklad_core/Models/NavState'
import { MyProductState } from '../server/mpsklad_core/Models/MyProductState'
import { IntegrationType } from '../server/mpsklad_core/Entity/IntegrationType'

/**
 * UI allows a nullable accountId, but not backend.
 **/
export type HomeNavState =
  Replace<NavState, {
    accountId: number | undefined
  }>

export type NavStore =
  Readonly<
    HomeNavState & {
    productsStateTab: MyProductState,
  }>

export const getIntegrationTypeName =
  (integrationType: IntegrationType): string =>
    integrationTypeNames[integrationType]