import { useState } from 'react'

import { WbAccountModel } from '../../../../server/mpsklad_core/Models/WbAccountModel'
import { WbDeliveryType } from '../../../../server/mpsklad_core/Entity/Wildberries/WbDeliveryType'

export const useWildberriesForm = (account: WbAccountModel | undefined) => {
  const [isLoading, setIsLoading] = useState(false)
  const [deliveryType, setDeliveryType] = useState(account?.deliveryType ?? WbDeliveryType.FBS)
  const [newToken, setNewToken] = useState(account?.tokenCombined ?? '')
  const [name, setName] = useState(account?.name ?? '')

  return {
    isLoading,
    setIsLoading,
    deliveryType,
    setDeliveryType,
    newToken,
    setNewToken,
    name,
    setName
  }
}