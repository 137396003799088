import React, { FormEvent, useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import { Box, FormHelperText, FormLabel, Grid, Typography } from '@material-ui/core'
import clsx from 'clsx'

import { SyncStartTableRow } from '../../SyncStartTableRow'
import { DefaultRadioGroup } from '../../DefaultRadioGroup'
import { OzonLoadOrdersForm } from '../../OzonLoadOrdersForm'
import { useHistory } from 'react-router-dom'

import { useOzonForm } from './hooks/useOzonForm'
import { useMessages } from '../../../hooks/snackbarHooks'
import { useApi, useLogic, useStore } from '../../../hooks/storeHook'
import { useRadioGroupStyles } from '../../../hooks/radioGroupStylesHook'
import { useSettingsPageStyles } from '../../../hooks/settingsPageStylesHook'
import { CommissionsRecordingType } from '../../../server/mpsklad_core/Entity/Base/CommissionsRecordingType'
import { EditOzonAccountModel } from '../../../server/mpsklad_core/Models/EditOzonAccountModel'
import { appRoutes } from '../../../common/appRoutes'
import { BootstrapInput } from '../../BootstrapInput'
import { OzonDeliveryType } from '../../../server/mpsklad_core/Entity/Ozon/OzonDeliveryType'

export const OzonApiSettings =
  observer(() => {
    const classes = useSettingsPageStyles()
    const radioGroupClasses = useRadioGroupStyles()

    const getDeliveryTypeClasses =
      (checked: boolean) =>
        clsx(radioGroupClasses.radioButton, checked && radioGroupClasses.selectedRadioButton)

    const {showSuccess, showError} = useMessages()

    const store = useStore()
    const logic = useLogic()
    const {userSync: {setOzonSyncStartDate}} = useApi()

    const account = logic.tryGetOzonAccount(store.homeNavRequired.accountId)
    const history = useHistory()

    const {
      isLoading,
      setIsLoading,
      deliveryType,
      setDeliveryType,
      apiKey,
      setApiKey,
      clientId,
      setClientId,
      name,
      setName
    } = useOzonForm(account)

    const deliveryTypeOptions =
      useMemo(() => [{
        value: OzonDeliveryType.FBS,
        label: 'Продажа со своего склада (FBS)'
      }, {
        value: OzonDeliveryType.FBO,
        label: 'Продажа со склада Ozon (FBO)'
      }, {
        value: OzonDeliveryType.RFBS,
        label: 'Продажа со своего склада и доставка силами продавца (realFBS)'
      }], [])

    const onSubmit = async (e: FormEvent) => {
      e.preventDefault()

      if (!apiKey || !clientId || !name) {
        showError('Необходимо заполнить все обязательные поля.')
        return
      }

      setIsLoading(true)

      try {
        const formData = {
          deliveryType,
          isUpdatePrices: account?.isUpdatePrices ?? false,
          isGeneratingPayments: account?.isGeneratingPayments ?? false,
          isUsingCommissions: account?.isUsingCommissions ?? false,
          ordersCommissionsRecording:
            account?.ordersCommissionsRecording ?? CommissionsRecordingType.Nothing,
          documentsCommissionsRecording:
            account?.documentsCommissionsRecording ?? CommissionsRecordingType.Nothing,
          name: name || undefined,
          msSalesReturnStoreId: account?.msSalesReturnStoreId,
          apiKey,
          clientId: parseInt(clientId),
          msData: {
            msOrganization: account?.msOrganization ?? '',
            msCounterparty: account?.msCounterparty ?? '',
            msContractId: account?.msContractId ?? undefined,
            msSalesChannelId: account?.msSalesChannelId ?? undefined,
            msProjectId: account?.msProjectId ?? undefined
          }
        } satisfies Omit<EditOzonAccountModel, 'id'>

        if (account) {
          await logic.editOzonAccount({id: account.id, ...formData})
        } else {
          const newAccount = await logic.createOzonAccount({id: 0, ...formData})

          history.push(appRoutes.CommonStore.settings.api.route({accountId: newAccount.id, integration: 'Ozon'}))
        }

        showSuccess('Сохранено!')
      } catch (e) {
        console.error('Failed to save Ozon account', e)
        showError('Ошибка при сохранении аккаунта')
      } finally {
        setIsLoading(false)
      }
    }

    return (
      <Box className={classes.pageContainer}>
        <Typography className={classes.header}>Настройки API</Typography>
        <form onSubmit={onSubmit}>
          <Grid container spacing={3}>
            {/* Left column */}
            <Grid item xs={12} md={6} style={account ? {minWidth: '50%'} : {}}>
              <Grid container spacing={3}>
                {/* API Token and API Key in one row */}
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                        <Grid item className={classes.root} xs={12}>
                          <Box>
                            <FormLabel component="legend" className={classes.label}>
                              <span className={classes.dash}>—</span>Id клиента
                              <span className={classes.star}>*</span>
                            </FormLabel>
                          </Box>

                          <BootstrapInput
                            value={clientId}
                            onChange={(e) => setClientId(e.target.value)}
                            fullWidth
                            placeholder="12228"
                            required
                            id="clientId"
                            className={classes.input}
                          />
                        </Grid>

                        <Grid item className={classes.root} xs={12}>
                          <Box>
                            <FormLabel component="legend" className={classes.label}>
                              <span className={classes.dash}>—</span>API ключ
                              <span className={classes.star}>*</span>
                            </FormLabel>
                          </Box>

                          <BootstrapInput
                            value={apiKey}
                            onChange={(e) => setApiKey(e.target.value)}
                            fullWidth
                            placeholder="be0813e9"
                            required
                            id="apiKey"
                            className={classes.input}
                          />

                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Schema of Work */}
                <Grid item xs={12}>
                  <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                    <Grid item className={classes.root} xs={12}>
                      <Box>
                        <FormLabel component="legend" className={classes.label}>
                          <span className={classes.dash}>—</span>Схема работы
                        </FormLabel>
                      </Box>

                      <DefaultRadioGroup
                        options={deliveryTypeOptions}
                        value={deliveryType}
                        onChange={setDeliveryType}
                        getOptionLabelClassName={() => radioGroupClasses.radioLabel}
                        getOptionClassName={getDeliveryTypeClasses}
                        name="ModelType"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* Account Name */}
                <Grid item xs={12}>
                  <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                    <Grid item className={classes.root} xs={12}>
                      <Box>
                        <FormLabel component="legend" className={classes.label}>
                          <span className={classes.dash}>—</span>Название аккаунта
                        </FormLabel>
                      </Box>

                      <BootstrapInput
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                        placeholder="Ozon 1234"
                        required
                        id="accountName"
                        className={classes.input}
                      />

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Right column */}
            {account &&
             <Grid item xs={12} md={6}>
               <Grid container spacing={3}>
                 {account.ordersTrackStartUnix &&
                  <Grid item xs={12}>
                    <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                      <Grid item className={classes.root} xs={12}>
                        <Box>
                          <FormLabel component="legend" className={classes.boldLabel}>
                            Дата начала синхронизации заказов
                          </FormLabel>
                        </Box>

                        <SyncStartTableRow account={account} onSubmit={setOzonSyncStartDate}/>

                        <FormHelperText className={classes.helperText}>
                          Будут синхронизированы заказы, созданные позже этой даты.
                        </FormHelperText>
                      </Grid>
                    </Grid>
                  </Grid>
                 }

                 <Grid item xs={12}>
                   <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                     <Grid item className={classes.root} xs={12}>
                       <Box>
                         <FormLabel component="legend" className={classes.boldLabel}>
                           Загрузка заказов
                         </FormLabel>
                       </Box>

                       <Grid container spacing={2}>
                         <Grid item xs={12}>
                           <OzonLoadOrdersForm ozonAccountId={account.id}/>
                         </Grid>
                       </Grid>
                     </Grid>
                   </Grid>
                 </Grid>
               </Grid>
             </Grid>
            }
          </Grid>

          <Box display="flex">
            <button
              type="submit"
              className={clsx(classes.saveButton, 'default-button')}
              disabled={isLoading}
            >
              <p>{isLoading ? 'Сохраняем...' : 'Сохранить'}</p>
            </button>
          </Box>
        </form>
      </Box>
    )
  })